import React, {Component} from 'react';
import {connect} from "react-redux";
import brown_logo from '../../../assets/icons/user_brown.svg';
import white_logo from '../../../assets/icons/user_white.svg';
import './Usericon.css';
import {withRouter} from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";

class Usericon extends Component{

    onClick = (state) => {
		this.setState({ true: !state });
    };

    logIn = () => {
        this.props.history.push({
            pathname: "/auth"
        });
    };

    render() {
		const icon = this.props.token.Token === undefined ?
			<img src={ white_logo } className="header_user_icon" alt="" onClick={this.logIn} />
			: <Dropdown history={this.props.history} />;

        return(
            <div className="header_user_icon_container">
                {icon}
            </div>
        )
    }
}
export default withRouter(connect(state =>({token: state.token}))(Usericon));