import React, { Component } from 'react';
import './Auth.css';
import MetaTags from "react-meta-tags";
import Page from "../../components/Page/Page";
import {Link} from "react-router-dom";
import InputMask from 'react-input-mask';
import {connect} from "react-redux";
import {addPhoneNumberToUser, addToken} from "../../actions";
import mainService from "../../services/mainService";
import bg from '../../assets/images/bgimg.png';

class Auth extends Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }
    componentDidMount() {
        this.contentRef.current.scrollIntoView(true)
    }

    componentDidUpdate() {
        this.contentRef.current.scrollIntoView(true)
    };
    state = {
        page: 1,
        privatePolicy: false,
        phoneNumber: "",
        errorAuth: null,
        wrongCode: null,
        timer: 60,
        disabledBtn: false
    };

    onClickGetCode = (e)=>{
        e.preventDefault();
        if(this.state.privatePolicy && this.state.numberFine && !this.state.disabledBtn){
            this.setState({disabledBtn: true});
            mainService.sendCodeToUser(this.state.phoneNumber).then(()=>{
                this.setState({page: 2});
            }).catch(()=>{
                this.setState({errorAuth: true, disabledBtn: false});
            });
        }
    };
    changePrivatePolicy = () =>{
      this.setState(({privatePolicy})=>{return{privatePolicy: !privatePolicy}});
    };
    onWritePhone = (e) => {
        this.setState({phoneNumber: e.target.value});
        if(e.target.value.search(/\+\s\d\s\(\d\d\d\)\s\d\d\d\s\d\d\s\d\d/g) !== -1){
            this.setState({numberFine: true, wrongCode: false})
        }else{
            this.setState({numberFine: false})
        }
    };
    onWriteCode = (e) => {
        this.setState({codeNumber: e.target.value});
        if(e.target.value.search(/\d\d\d\s\d\d\d/g) !== -1){
            this.setState({codeFine: true})
        }else{
            this.setState({codeFine: false})
        }
    };
    onClickChangeNumber = (e) => {
        e.preventDefault();
        this.setState({page: 1, numberFine: null, privatePolicy: false, disabledBtn: false})
    };
    onClickContinue = (e) => {
        e.preventDefault();
        mainService.authenticate({code: this.state.codeNumber.split(" ").join(""), phone: this.state.phoneNumber}).then((res)=>{
           this.props.addToken(res.data);
            if(this.props.location.state !== undefined){
                this.props.history.push({
                    pathname:"/order",
                });
            }else{
                this.props.history.push({
                    pathname:"/",
                });
            }

        }).catch((res)=>{
            this.setState({wrongCode: true});
        });
    };
    render() {
        return(
            <Page>
                <MetaTags>
                    <title>Авторизация | Семейный Дворик</title>
                </MetaTags>
                <div className="auth-main" ref={this.contentRef}>
                    <div className="auth-container">
                        {this.state.page === 1 && <AutorisationForm
                            onClickGetCode={this.onClickGetCode}
                            privatePolicy={this.state.privatePolicy}
                            changePrivatePolicy={this.changePrivatePolicy}
                            onWritePhone = {this.onWritePhone}
                            numberFine = {this.state.numberFine}
                            error = {this.state.errorAuth}
                        />}
                        {this.state.page === 2 && <AcceptForm phoneNumber={this.state.phoneNumber}
                                                              onWriteCode = {this.onWriteCode}
                                                              codeFine = {this.state.codeFine}
                                                              onClickChangeNumber = {this.onClickChangeNumber}
                                                              onClickContinue = {this.onClickContinue}
                                                              wrongCode = {this.state.wrongCode}
                                                              timer = {this.state.timer}
                        />}
                    </div>
                </div>
            </Page>
        )
    }
}
class AutorisationForm extends Component  {
    state = {privatePolicy: false};

    render() {
        return(
            <form>
                <div className="auth-header universal-page-header-text">Авторизация</div>
                <div className="auth-description auth-text">Введите номер телефона, <br/> чтобы мы могли уведомлять  Вас о заказе</div>
                {this.props.error && <div className="auth-error">! Ошибка авторизации !</div>}
                <label className="auth-input-text number-text">
                    <InputMask mask="+ 7 (\999) 999 99 99"
                               maskChar=" "
                               placeholder="+ 7 (000) 000 00 00"
                               onChange={(e)=>this.props.onWritePhone(e)}
                               className={`${this.props.numberFine === true ? "auth-input-fine": ""}
                                           ${this.props.numberFine === false || this.props.error ? "auth-input-bad": ""}`}
                    />
                </label>
                <label className="auth-input-checkbox auth-text">
                    <input type="checkbox" checked={this.props.privatePolicy} onChange={this.props.changePrivatePolicy} />
                    <div>Я согласен с <Link to="/page/privat-policy">Политикой конфиденциальности</Link></div>
                </label>
                <button className={`auth-btn ${this.props.privatePolicy && this.props.numberFine ? "" : "auth-btn-disabled"}`} onClick={(e)=>this.props.onClickGetCode(e)}>Получить код</button>
            </form>
        )
    }
}

class AcceptForm extends Component{
    state = {time: 60};
    timer;
    componentDidMount() {
        this.timer = setInterval(()=>{
            if(this.state.time > 0){
                this.setState(({time})=>{
                    return({time: time - 1})
                })
            }
        }, 1000)
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    onClickGetCodeAgain = (e)=>{
        e.preventDefault();
        if(this.state.time === 0){
            mainService.sendCodeToUser(this.props.phoneNumber).then((res)=>{
                this.setState({againSended: true, time: 60});
            }).catch((res)=>{console.error(res)});
        }
    };

    render() {
        return(
            <form>
                <div className="auth-header universal-page-header-text">Подтверждение</div>
                <div className="auth-description auth-text">
                    На телефон <font>{this.props.phoneNumber}</font> <br/>
                    {this.state.againSended && "повторно "}отправлен код подтверждения, <br/>
                    введите его для продолжения оформления заказа</div>
                {this.props.wrongCode && <div className="auth-error">Неверный код</div>}
                <label className="auth-input-text number-text">
                    <InputMask mask="999 999"
                               maskChar=" "
                               placeholder="000 000"
                               onChange={(e)=>this.props.onWriteCode(e)}
                               className={`${this.props.codeFine === true ? "auth-input-fine": ""}
                                           ${this.props.codeFine === false || this.props.wrongCode ? "auth-input-bad": ""}`}
                    />
                </label>
                <button className={`auth-btn ${this.props.codeFine ? "" : "auth-btn-disabled"}`} onClick={this.props.onClickContinue}>Войти</button>
                <button className="auth-some-btn" onClick={this.props.onClickChangeNumber}> Изменить номер телефона </button>
                <button className={`auth-some-btn-timer ${this.state.time > 0 ? "auth-btn-timer-disable": ""}`}
                        onClick={this.onClickGetCodeAgain}>
                    Отправить код заного <div className={this.state.time === 0 ? "auth-timer": ""}>[{this.state.time} сек]</div>
                </button>
            </form>
        )
    }
}
export default connect(state=>({}), dispatch => ({
    addPhone: (phone)=> {dispatch(addPhoneNumberToUser(phone))},
    addToken: (token)=> {dispatch(addToken(token))}
}))(Auth)