import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Page from "../../../components/Page/Page";

export default class Offer extends Component {
	render() {
		return (
			<Page>
				<MetaTags>
					<title> Публичная оферта | Семейный дворик </title>
				</MetaTags>

				<div className="offer-cont">
					<h1>Публичная оферта о продаже товаров дистанционным способом</h1>
					<h1> (действует с 21 марта 2018 года)  </h1>
					<h3>1. Термины и определения</h3>
					<div>
						<p>1.1. в настоящей оферте, если из контекста не следует иное, нижеприведенные термины с заглавной буквы имеют следующие значения.</p>
						<p>1.1.1. "Курьерская служба" - лица, оказывающие услуги по доставке Товаров от имени Продавца, или сотрудники Продавца. .</p>
						<p>1.1.2. "Продавец" - Индивидуальный предприниматель Яковенко Наталья Сергеевна, (ИНН 402720065581, ОГРНИП 316402700100826), местонахождение по адресу: 248000, г.Калуга, ул. Циолковского, дом 5, кв.70, созданное в соответствии с законодательством Российской Федерации и осуществляющее предпринимательскую деятельность по продаже Товаров, в том числе дистанционным способом. .</p>
						<p>Полное фирменное наименование и место нахождения Продавца указывается в настоящей оферте на Сайте. Адрес изготовления Товара указывается на Сайте. .</p>
						<p>1.1.3. "Покупатель" - любое дееспособное лицо, намеренное заказать или приобрести, либо заказывающий, приобретающий или использующий Товары исключительно для личных, семейных, домашних или иных нужд, не связанных с осуществлением предпринимательской деятельности. .</p>
						<p>1.1.4. "Сайт" - www.semeinyidvorik.ru.</p>
						<p>1.1.5. "Телефон" - 8(960)518-08-03. .</p>
						<p>1.1.6. "Товары" - перечень продукции Продавца, представленный на Сайте. Товары могут отличаться от изображения на Сайте. .</p>
						<p>1.2. Для целей настоящей оферты термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе. .</p>
					</div>
					
					<h3>2. Общие положения</h3>

					<div>
						<p>2.1. Продавец и Покупатель гарантируют, что обладают необходимой право- и дееспособностью, а также всеми правами, необходимыми для заключения и исполнения договора розничной купли-продажи дистанционным способом. .</p>
						<p>2.2. Покупатель обязуется ознакомиться с условиями настоящей оферты, с информацией, указанной на Сайте в отношении Товаров, с условиями участия в Программе лояльности, Политикой Конфиденциальности, Пользовательским соглашением. .</p>
						<p>2.3. Продавец оставляет за собой право вносить изменения в настоящую оферту, в связи с чем Покупатель обязуется самостоятельно отслеживать наличие изменений в настоящей публичной оферте. Новая редакция оферты вступает в силу с момента ее размещения. Действующая редакция публичной оферты находится на Сайте. .</p>
						<p>2.4. при оформлении заказа Товаров Покупатель тем самым подтверждает, что он исполнил обязанности, предусмотренные пунктом 2.2. настоящей оферты. .</p>
						<p>2.5. Договор розничной купли-продажи дистанционным способом считается заключенным с момента получения Продавцом сообщения о намерении Покупателя приобрести Товары. .</p>
						<p>2.6. Срок, в течение которого действует предложение о заключении договора розничной купли-продажи дистанционным способом, является бессрочным. .</p>
					</div>

					<h3>3. Предмет Договора</h3>
					<div>
						<p>3.1. Продавец продает Товары по ценам, представленным на Сайте Продавца, и доставляет Товар Курьерской службой, а Покупатель производит оплату и принимает Товары в соответствии с условиями настоящей оферты. .</p>
						<p>3.2. Право собственности на Товары переходит к Покупателю в момент приемки Товаров Покупателем. .</p>
					</div>
					
					<h3>4. Уведомления Покупателя</h3>
					<div>
						<p>4.1. Продавец вправе осуществлять рассылку уведомлений о проводимых акциях и рекламных материалов Пользователю по сетям электросвязи, при условии получения от Пользователя согласия на совершение указанных действий. .</p>
						<p>4.2. Покупатель имеет право в любой момент отписаться от уведомлений, указанных в пункте 4.1. настоящей оферты, и подписаться на уведомления вновь. .</p>
						<p>4.3. Продавец вправе совершать вызовы в адрес Пользователя в целях проведения маркетинговых исследований среди Покупателей и исследования предпочтений Покупателей; проведения розыгрышей призов среди Покупателей; проведения анализа оказываемых Продавцом услуг; урегулирования конфликтных ситуаций без получения от Пользователя согласия на совершение всех перечисленных выше действий. .</p>
						<p>4.4. Продавец вправе отправлять сервисные сообщения, информирующие Покупателя о совершенном им заказе, его составе, этапах его обработки и о готовности заказа; такие сервисные сообщения отправляются автоматически, не могут быть отклонены Покупателем, направлены на контроль качества оказания услуг Покупателю и его информирование о надлежащем исполнении Продавцом своих обязательств по договору купли-продажи дистанционным способом. .</p>
					</div>

					<h3>5. Оформление заказа Товаров</h3>
					<div>
						<p>5.1. Заказ Покупателя может быть оформлен по Телефону и/или посредством заполнения электронной формы заказа на Сайте. .</p>
						<p>5.2. Покупатель обязуется предоставить достоверные данные, необходимые и достаточные для исполнения договора розничной купли-продажи Товаров дистанционным способом. .</p>
						<p>5.3. в случае возникновения у Покупателя дополнительных вопросов, касающихся Товаров, перед оформлением Заказа, Покупатель должен обратиться к Продавцу по Телефону для получения необходимой информации, в соответствии с режимом работы, указанным на Сайте. .</p>
						<p>5.4. Покупатель может заказать только те Товары, которые есть в наличии у Продавца в момент оформления заказа Товаров у Продавца. .</p>
						<p>5.5. Покупатель не имеет право изменить состав заказа. .</p>
						<p>5.6. При оформлении заказа по Телефону Продавец вправе осуществлять аудиозапись телефонного разговора с Пользователем, если указанные действия необходимы Продавцу для исполнения обязанностей, предусмотренных настоящей офертой, и оценки качества оказанных услуг, без получения от Пользователя согласия на совершение указанных действий. .</p>
						<p>5.7. При оформлении заказа посредством заполнения электронной формы заказа на Сайте Покупатель создает учетную запись в соответствии с Пользовательским соглашением, указанным на Сайте. .</p>
						<p>5.8. Если у Продавца отсутствует необходимое количество или ассортимент заказанных Покупателем Товаров, Продавец информирует об этом Покупателя по телефону в течение 60 минут после получения заказа Товаров от Покупателя. Покупатель вправе согласиться принять Товары в ином количестве или ассортименте, либо аннулировать свой заказ Товаров. В случае неполучения ответа Покупателя Продавец вправе аннулировать Заказ Покупателя в полном объеме. .</p>
						<p>5.9. Продавец вправе временно приостановить прием заказов Товаров в случае технических неполадок, не позволяющих принять заказа Товаров, или отсутствия ингредиентов, необходимых для изготовления Товаров. .</p>
					</div>
					

					<h3>6. Доставка Товаров</h3>
					<div>
						<p>6.1. Доставка Товаров осуществляется Курьерской службой по фактическому адресу, указанному Покупателем, при оформлении заказа или выборкой Товаров Покупателем (самовывозом). .</p>
						<p>6.2. Доставка Товаров в пределах зоны доставки осуществляется в соответствии с режимом работы Продавца, указанным на Сайте. .</p>
						<p>6.3. Доставка Товаров по адресам, не входящим в зону доставки, не осуществляется. .</p>
						<p>6.4. Средний срок доставки заказа Товаров - 60 минут в зоне часовой доступности с момента принятия заказа Товаров. Время доставки отдаленных районов и больших заказов, а так же в период акций может быть увеличено. В случае нарушения установленных сроков доставки Товаров, в силу обстоятельств непреодолимой силы ("форс-мажор") в соответствии с Разделом 9 настоящей оферты Продавец не считается просрочившим доставку. .</p>
						<p>6.5. Доставка осуществляется при условии заказа Товаров на сумму минимального заказа. Сумма минимального заказа определяется Продавцом в одностороннем порядке и указывается на Сайте. .</p>
						<p>6.6. Продавец вправе отказаться от исполнения договора розничной купли-продажи дистанционным способом в том случае, если Курьерская служба по прибытии к фактическому адресу доставки Покупателя, не может осуществить фактическую передачу доставленных Товаров по вине Покупателя (Покупатель в течение 10 минут не отвечает на телефонный вызов или не открывает дверь Курьерской службе). .</p>
					</div>
					

					<h3>7. Оплата Товаров</h3>
					<div>
						<p>7.1. Цены на Товары определяются Продавцом в одностороннем порядке и указываются на Сайте. Цена на заказанный Покупателем Товар изменению не подлежит. .</p>
						<p>7.2. Оплата Товаров Покупателем производится в рублях Российской Федерации одним из следующих способов: .</p>
						<p>7.2.1. оплата осуществляется по факту получения Товаров наличными денежными средствами, оплата наличными денежными средствами ограничена максимальной суммой. Максимальная сумма заказа, при которой возможна оплата наличными денежными средствами, определяется Продавцом в одностороннем порядке и указывается на Сайте. Заказ превышающий размер максимальной суммы оплачивается в соответствии с п.7.2.2. настоящей оферты; .</p>
						<p>7.2.2. безналичными расчетами банковской картой на Сайте, в соответствии с положением ЦБ РФ "Об эмиссии банковских карт и об операциях, совершаемых с использованием платежных карт" № 266-П от 24.12.2004 года операции по банковским картам совершаются держателем карты либо уполномоченным им лицом; .</p>
						<p>7.2.3. безналичными расчетами банковской картой по факту получения Товаров, при условии оснащения Курьерской службы мобильными POS-терминалами (о наличии возможности произвести оплату Товаров данным способом следует уточнять у Продавца). .</p>
						<p>7.3. Выдача либо направление Покупателю кассового чека: .</p>
						<p>7.3.1.На бумажном носителе, в случае совершения заказа Товаров через Телефон и оплаты в порядке, предусмотренном пунктом 7.2.1 настоящей оферты, пунктом 7.2.3 настоящей оферты, если Покупатель в момент совершения заказа не сообщил Продавцу о желании получить кассовый чек в электронном виде, и не выполнил условия, предусмотренные пунктом 7.4.2 настоящей оферты. .</p>
						<p>7.3.2. в электронном виде на электронную почту Покупателя, в случае совершения заказа Товаров через Сайт и оплаты Товаров в порядке, предусмотренном пунктом 7.2.2 настоящей оферты, если Покупатель выполнил условия пункта 7.4.1. настоящей оферты. .</p>
						<p>7.3.3. в электронном виде способом, технически доступным для Продавца, в случае совершения заказа Товаров через Сайт и оплаты Товаров в порядке, предусмотренном пунктом 7.2.2 настоящей оферты, если Покупатель не выполнил условия пункта 7.4.1. настоящей оферты. .</p>
						<p>7.4. Условия направления кассового чека в электронном виде: .</p>
						<p>7.4.1. Внесение Покупателем данных об адресе электронной почты в личную учетную запись на Сайте при регистрации в порядке, предусмотренном Пользовательским соглашением; .</p>
						<p>7.4.2. Внесение Покупателем данных об адресе электронной почты в личную учетную запись на Сайте при регистрации в порядке, предусмотренном Пользовательским соглашением, и при условии совершения Покупателем заказа Товаров с номера телефона, который идентичен номеру телефона, внесенному Покупателем в личной учетной записи на Сайте в порядке, предусмотренном Пользовательским соглашением. .</p>
					</div>
					
					<h3>8. Возврат и обмен Товаров</h3>
					<div>
						<p>8.1. Возврат Товара надлежащего качества не допускается. .</p>
						<p>8.2. Продавец обязан передать Покупателю Товары, которые полностью соответствуют его заказу, качество которых соответствует информации, представленной Покупателю при заключении договора розничной купли-продажи дистанционным способом, а также информации, доведенной до его сведения при передаче Товаров (на этикетке или вкладыше, прикрепленных к Товарам или их упаковке, либо другими способами, предусмотренными для отдельных видов Товаров). .</p>
						<p>8.3. при получении Товаров Покупатель проверяет соответствие полученных Товаров своему заказу, комплектность и отсутствие претензий к внешнему виду доставленных Товаров в течение 10 (Десяти) минут после получения Товаров. .</p>
						<p>8.4. в случае получения некачественных Товаров, либо несоответствия полученных Товаров заказанному (отсутствие заявленных ингредиентов в Товаре), Покупатель вправе потребовать замены таких Товаров Товарами надлежащего качества сразу в течение 5 (Пяти) минут после получения Товаров. .</p>
						<p>8.5. в случае невыполнения порядка предъявления претензии Покупателя по внешнему виду Товаров и их комплектности, предусмотренных пунктом 8.3, пунктом 8.4 настоящей оферты, претензии не принимаются. .</p>
					</div>
					

					<h3>9. Форс-мажор</h3>
					<p>9.1. Любая из Сторон освобождается от ответственности за полное или частичное неисполнение своих обязательств по настоящей оферте, если это неисполнение было вызвано обстоятельствами непреодолимой силы. Обстоятельства непреодолимой силы означают чрезвычайные события и обстоятельства, которые Стороны не могли ни предвидеть, ни предотвратить разумными средствами. Такие чрезвычайные события или обстоятельства включают в себя, в частности: забастовки, наводнения, пожары, землетрясения и иные стихийные бедствия, войны, военные действия и так далее. .</p>

					<h3>10. Ответственность Сторон публичной оферты</h3>
					<div>
						<p>10.1. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования им Товаров, приобретённых у Продавца. .</p>
						<p>10.2. Продавец не отвечает за убытки Покупателя, возникшие в результате неправильного заполнения Заказа, в том числе неправильного указания своих данных. .</p>
						<p>10.3. В случае злоупотребления Покупателем правами потребителя, включая, но не ограничиваясь, если Покупатель отказался от приемки и/или оплаты заказанного и доставленного Товара, Продавец вправе реализовать свое право на самозащиту, а именно: не принимать от него будущие Заказы. .</p>
						<p>10.4. В случае одностороннего отказа Продавца от исполнения договора розничной купли-продажи дистанционным способом в соответствии с пунктом 6.6. настоящей личной оферты, Продавец вправе реализовать свое право на самозащиту, взыскать убытки с Покупателя. В случае, если Покупатель оплатил Товар до его доставки, оплаченная сумма не возвращается. .</p>
					</div>
					
				</div>
			</Page>
		);
	}
}