import React, { Component } from 'react';
import './Header.css';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import PhoneNumber from './PhoneNumber/PhoneNumber';
import ShoppingCart from './ShoppingCart/ShoppingCart';
import Usericon from "./Usericon/Usericon";

import logo from '../../assets/images/logo.svg';
import Navigation from './Navigation/Navigation';

export default class Header extends Component{
	constructor() {
		super();
		this.state = {
			isActiveHamburgerMenu: false
		};
	}
	onClickHamburgerMenu = () => {
		this.props.onClickHamburgerMenu(!this.props.isNotVisibleSidebar);
	}

    render(){
		return (
			<header>
				<div className="header-top">
					<HamburgerMenu active={!this.props.isNotVisibleSidebar} onClickHamburgerMenu={this.onClickHamburgerMenu}/>
					<PhoneNumber />
					<div className="shopping-cart-container">
						 <Usericon />
						<ShoppingCart />
					</div> 
				</div>

				<div className="header-mid">
					<div className="header-logo">
						<img className="header-logo-image" src={logo} alt="" />
					</div>
				</div>

				<div className="header-bot">
					<Navigation />
				</div>
			</header>
		);
    }
}