import React, {Component} from 'react';
import './BtnsBackOnward.css';

class BtnsBackOnward extends Component{
    constructor(props) {
        super(props)
        this.onForward = this.onForward.bind(this)
        this.state = { buttonsClass: "btnsBackOnward"}
    }
    onForward() {
        if (this.props.checkProps()) {
            this.setState({
                buttonsClass: "btnsBackOnwardDisabled"
            })
            this.props.onClickOnward()
        }
        else {
            this.props.contentRef.current.scrollIntoView(true)
        }
    }
    render() {
        return(
            <div className={this.state.buttonsClass}>
                {/*{this.props.step !== 1 ? <div className="btnsBackOnward-back" onClick={this.props.onClickBack}>Назад</div> : <div></div>}*/}
                {this.props.step === 3 ? <div className="btnsBackOnward-onward"
                    onClick={this.onForward}>Заказать</div> : <div className="btnsBackOnward-onward" onClick={this.props.onClickOnward}>Далее</div>}
            </div>
        )
    }
}
export default BtnsBackOnward;