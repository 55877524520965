import React, {Component} from 'react';
import './ContactStep.css';
//import BtnsBackOnward from "../BtnsBackOnward/BtnsBackOnward";
import Toast from "../../../components/Toast/Toast";

class ContactStep extends Component {
    render() {
        return(
                <form className="">
                <div className="order-notice order-checkbox-text-active">* - обязательное поле</div>
                    {this.props.contactWarning && <Toast text="Заполните все обязательные поля"/>}

                    <div>
                    <label className="contactsStep-label order-checkbox-text-active">
                            Имя<sup>*</sup>
                        <input type="text" placeholder="Имя" onChange={this.props.onWriteName} value={this.props.name}/> 
                        </label>
                    </div>

                    <div>
                    <label className="contactsStep-label2 order-checkbox-text-active">
                            Email
                            <input type="email" placeholder="semejnyjdvorik@gmail.com" onChange={this.props.onWriteEmail} value={this.props.email}/>
                        </label>
                    </div>
                {/*<BtnsBackOnward
                        onClickBack = {this.props.onClickBack}
                        onClickOnward = {this.props.onClickOnward}
                        step = {this.props.step}
                    />*/}
                </form>
        )
    }
}
export default ContactStep;