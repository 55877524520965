import React from 'react';
import './Dropdown.css';
import orange_logo from '../../../assets/icons/user_orange.svg';
import {connect} from "react-redux";
import {deleteToken} from "../../../actions";


class Dropdown extends React.Component {
    constructor(){
        super();

        this.state = {
            displayMenu: false,
        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

    };

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }
    onClickMyOrders = () => {
      this.props.history.push("/myorders");
    };

    render() {
        return (
            <div  className="dropdown-header">
                    <img src={orange_logo} className="header_user_icon" alt="" onClick={this.showDropdownMenu}/>


                { this.state.displayMenu ? (
                        <ul>
                            <li onClick={this.onClickMyOrders}>Мои заказы</li>
                            <li onClick={this.props.deleteToken}>Выйти</li>
                        </ul>
                    ):
                    (
                        null
                    )
                }

            </div>

        );
    }
}

export default connect(state =>({token: state.token}), dispatch => ({deleteToken: ()=>{dispatch(deleteToken())}}))(Dropdown);