import React from 'react';
import './Loader.css';

const Loader = () =>{
    return (
        <div className="loader-container">
           <div className="cssload-bell">
                <div className="cssload-circle">
                    <div className="cssload-inner"></div>
                </div>
                <div className="cssload-circle">
                    <div className="cssload-inner"></div>
                </div>
                <div className="cssload-circle">
                    <div className="cssload-inner"></div>
                </div>
                <div className="cssload-circle">
                    <div className="cssload-inner"></div>
                </div>
                <div className="cssload-circle">
                    <div className="cssload-inner"></div>
                </div>
            </div>
        </div>
    )
};
export default Loader;