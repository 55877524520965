import React, { Component, Fragment } from 'react';
import './Cart.css';
import MetaTags from 'react-meta-tags';
import deleteIcon from '../../assets/icons/delete.svg';
import Page from "../../components/Page/Page";
import {connect} from 'react-redux';
import {updateSumAndCount} from "../../actions";
import mainService from "../../services/mainService";
import bg from '../../assets/images/bgimg.png';

class Cart extends Component{
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }

    componentDidUpdate() {
        //this.contentRef.current.scrollIntoView(true)
    };

    state = {isEmpty: false, items: [], promocode: "", popupOpened: false, enablePromo: true};
    clearCart = () => {
      localStorage.removeItem("sdvItems");
      this.props.updateCounter();
      this.setState({items: []});
    };
    setSum = (sum) => {
        this.setState({sum: sum});
        if(sum <= 0)this.setState({items: []})
    };
    changeStatus = (status) => {
        this.setState({isEmpty: status})
    };
    toOrder = ()=>{
        if(this.props.token.Token){
            this.props.history.push({
                pathname:"/order",
            });
        }else{
            this.props.history.push({
                pathname:"/auth",
                state:{
                    redirect: "/order"
                }
            });
        }
    };
    getIDs = () => {
        if (localStorage.getItem("sdvItems") !== null){
            let arr = JSON.parse(localStorage.getItem("sdvItems")).items;
            let obj = {};
            arr.forEach((item)=>{obj[item] = obj[item] + 1 || 1});
            return obj;
        }else{
            // Корзина пуста
        }
    };
    calculateSum = () =>{
        let sum = 0;
        if(this.state.items){
            this.state.items.forEach((item)=>{sum += item.count * item.cost});
            this.setSum(sum);
        }
        this.props.updateCounter();
    };
    onClickAdd = (index, id, cost) => {
        let obj = this.state.items;
        obj[index].count++;
        this.setState({items: obj});
        if(localStorage.getItem("sdvItems") !== null){
            let obj2 = JSON.parse(localStorage.getItem("sdvItems"));
            obj2.lastUpdate = new Date();
            obj2.items.push(id);
            obj2.sum += cost;
            localStorage.setItem("sdvItems", JSON.stringify(obj2));
        }
        this.calculateSum();
    };
    onClickReduce = (index, id, cost) => {
        let obj = this.state.items;
        if(obj[index].count <= 1){
            return false
        }else{
            obj[index].count--;
        }
        this.setState({items: obj});
        if(localStorage.getItem("sdvItems") !== null) {
            let obj2 = JSON.parse(localStorage.getItem("sdvItems"));
            obj2.lastUpdate = new Date();
            let elem = obj2.items.indexOf(id);
            obj2.items.splice(elem, 1);
            obj2.sum -= cost;
            localStorage.setItem("sdvItems", JSON.stringify(obj2));
        }
        this.calculateSum();
    };
    onClickRemove = (index, id, cost) => {
        let obj = this.state.items;
        obj.splice(index, 1);
        this.setState({items: obj});
        if(localStorage.getItem("sdvItems") !== null) {
            let obj2 = JSON.parse(localStorage.getItem("sdvItems"));
            obj2.lastUpdate = new Date();
            obj2.items = obj2.items.filter((item) => {
                if (item === id) obj2.sum -= cost;
                return item !== id;
            });
            localStorage.setItem("sdvItems", JSON.stringify(obj2));
        }
        this.calculateSum();
    };
    onChangePromocode = (e) => {
      this.setState({promocode: e.target.value})
    };
    onUsePromocode = () => {
      if (this.state.promocode !== "" && this.state.enablePromo){
          this.setState({enablePromo: false});
          mainService.usePromocode(this.props.token.Token, this.state.promocode)
              .then((res)=>{
                 this.setState({promocodeItems: res.data.data, popupOpened: true, message: null, enablePromo: true});
              }).catch((err)=> {
                  if(err.response !== undefined){
                      if(err.response.status === 400 || err.response.status === 401){
                          this.setState({
                              popupOpened: true,
                              unauthorize_available: err.response.data.info.unauthorize_available,
                              message: err.response.data.info.message,
                              promocodeItems: undefined,
                              enablePromo: true})
                      }
                  }
              })
      }
    };

    closePopup = () => {
      this.setState({popupOpened: false, promocode: ""})
    };

    componentDidMount() {
        this.checkDataOnServer()
        this.contentRef.current.scrollIntoView(true)
    }
    checkDataOnServer = () => {
        let data = [];
        let obj = this.getIDs();
        for (let key in obj){
            data.push({id: key, count: obj[key]});
        }
        // Отправить данные на сервер
        let dataForServer = [];
        data.forEach((item)=>{
            dataForServer.push({
                "ProductVariationId" : item.id,
                "Count" : item.count
            })
        });
        mainService.getCartItems(dataForServer).then((res)=>{
            let arr = [];
            res.data.forEach((item)=>{
                arr.push({
                    name: item.ProductName,
                    count: item.Count,
                    cost: item.Price,
                    id: item.Id,
                    unit: item.Unit.Name,
                    unitcount: item.Unit.Count,
                    tradestatus: item.TradeStatus
                })
            });
            this.setState({items: arr});
        }).then(()=>{
            setTimeout(()=>this.calculateSum(),200);
        }).catch(()=>{
            //Что-то пошло не так
            this.changeStatus(true);
            alert("Ошибка")
        })
    };

    addPromoItems = (arr) => {
        if(localStorage.getItem("sdvItems") !== null){
            let obj2 = JSON.parse(localStorage.getItem("sdvItems"));
            obj2.lastUpdate = new Date();
            arr.forEach((item)=>{
                obj2.items.push(item.Id);
                obj2.sum += item.Price;
            });
            localStorage.setItem("sdvItems", JSON.stringify(obj2));
        }else{
            let obj3 = {
                lastUpdate: new Date(),
                items: [],
                sum: 0
            };
            arr.forEach((item)=>{
                obj3.items.push(item.Id);
                obj3.sum += item.Price;
            });
            localStorage.setItem("sdvItems", JSON.stringify(obj3));
        }
        this.checkDataOnServer();
    };

    render() {
        const content = this.state.items.length < 1 ? <CartEmpty /> :
            <CartList setSum={this.setSum}
                update={this.props.updateCounter}
                changeStatus={this.changeStatus}
                items={this.state.items}
                onClickAdd={this.onClickAdd}
                onClickReduce={this.onClickReduce}
                onClickRemove={this.onClickRemove} />;

        const recomendations = <div className="recomenden-list-layout-only" ></div>; {/*this.state.items.length < 1 ? <div className="recomenden-list-layout-only"></div> :
            <React.Fragment>
                <h2 className="recomended-sub-title cart-sub-titile-text">РЕКОМЕНДУЕМ К ВАШЕМУ ЗАКАЗУ</h2>
                <div className="recomenden-list">{/*Здесь будет блок-слайдер рекомендуемых товаров.</div>
            </React.Fragment > */}

        return(
            <Page>
                <MetaTags>
                    <title>Корзина | Семейный Дворик</title>
                </MetaTags>
                <div className="cart-main" ref={this.contentRef}>
                    <div className="cart-content">

                        <div className="cart-title-container">
                            <div className="order-process">
                                <div className="order-process-item-box">
                                    <div className="order-process-corcle-item-selected order-process-corcle-item-text-selected">1</div>
                                    <p className="order-process-text-selected">КОРЗИНА</p>
                                </div>
                                <div className="order-process-between-line"></div>
                                <div className="order-process-item-box order-process-ordering">
                                    <div className="order-process-corcle-item order-process-corcle-item-text">2</div>
                                    <p className="order-process-text">ОФОРМЛЕНИЕ<br></br>ЗАКАЗА</p>
                                </div>
                                <div className="order-process-between-line"></div>
                                <div className="order-process-item-box order-process-confirmed">
                                    <div className="order-process-corcle-item order-process-corcle-item-text">3</div>
                                    <p className="order-process-text">ЗАКАЗ<br></br>ПРИНЯТ</p>
                                </div>
                            </div>
                            <h2 className="cart-title universal-page-header-text">Корзина</h2>
                            {/*<div className="cart-clean"
                                 style={this.state.items.length < 1 ?{"display": "none"}:null}
                                 onClick={this.clearCart}>Очистить корзину</div>*/}
                        </div>
                        { content }
                        {this.state.popupOpened && <PromoPopup message={this.state.message}
                                                               unauthorize_available={this.state.unauthorize_available}
                                                               promocode={this.state.promocode}
                                                               promocodeItems={this.state.promocodeItems}
                                                               history={this.props.history}
                                                               addPromoItems={this.addPromoItems}
                                                               closePopup={this.closePopup}
                                                               isAuthorised={this.props.token.Token !== undefined}
                        />}
                        { recomendations }
                        <h2 className="promocode-sub-title cart-sub-titile-text">ПРОМОКОД</h2>
                        <div className="promocode">
                            <input type="text" placeholder="" onChange={(e)=>this.onChangePromocode(e)} value={this.state.promocode}/>
                            <button onClick={this.onUsePromocode}>Применить</button>
                        </div>
                        <div className="cart-to-order" style={this.state.items.length < 1 ?{"display": "none"}:null}>
                            {/*<div className="cart-to-order-right">*/}
                                <div className="cart-to-order-sum cart-full-sum-text">Итого: {this.state.sum}<span className="ruble">&#8381;</span></div>
                                <button className="cart-to-order-order" onClick={this.toOrder}>Оформить заказ</button>
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}

const CartEmpty = () =>{
    return (
            <div className="cart-empty">
                <hr className="empty-cart-row-line"></hr>
                <div className="simple-text">Корзина пуста</div>
                <hr className="empty-cart-row-line"></hr>
            </div>
    )
};

const CartRowLine = () => {
    return (
        <React.Fragment>
            <span className="cart-row-line"></span>
        </React.Fragment>
    )
}

const CartRow = ({ index, name, count, cost, sum, unitcount, unit, tradestatus, onClickAdd, onClickReduce, onClickRemove, disabledBtnLess }) => {
    return (
        <React.Fragment>
            <CartRowLine />
            {/*<div className="cart-num cart-row-text">{index}.</div>*/}
            <div className="cart-name cart-row-text"><b>{name}</b><p className="cart-measure-info-text">{unitcount}{unit}</p></div>
            <div className="cart-add cart-row-text" >{tradestatus == true ? <div><BtnLess onClickReduce={onClickReduce} disabledBtnLess={disabledBtnLess} /><span className="cart-span-btns">{count}</span><BtnMore onClickAdd={onClickAdd} /></div> : <span className="cart-span-btns">{count}</span>}</div>      
            {/*<div className="cart-cost cart-row-text">{cost}<span className="ruble">&#8381;</span></div>*/}
            <div className="cart-sum cart-row-text">{sum}<span className="ruble">&#8381;</span></div>
            <div className="cart-delete-icon" onClick={onClickRemove}><img src={deleteIcon} alt="Удалить" /></div>
            <CartRowLine />
        </React.Fragment>
  )
};

class CartList extends Component{
    render() {
        return(
            <div className="cart-list">
                <div className="cart-grid-container">
                    {this.props.items && this.props.items.map((item, index) =>
                        <CartRow index={index + 1}
                                 key={index}
                                 name={item.name}
                                 count={item.count}
                                 cost={item.cost}
                                 sum={item.cost * item.count}
                                 unitcount= {item.unitcount}
                                 unit= {item.unit}
                                 tradestatus= {item.tradestatus}
                                 disabledBtnLess={item.count <= 1}
                                 onClickAdd = {()=>this.props.onClickAdd(index, item.id, item.cost)}
                                 onClickReduce = {()=>this.props.onClickReduce(index, item.id, item.cost)}
                            onClickRemove={() => this.props.onClickRemove(index, item.id, item.cost)}
                        
                        />)}
                </div>
            </div>
        )
    }
}

const BtnLess = ({onClickReduce, disabledBtnLess})=>{
  return(
      <button className={`cart-btns  ${disabledBtnLess ? "cart-btns-disabled" : ""}`} onClick={onClickReduce}>
          -
      </button>
  )
};
const BtnMore = ({onClickAdd})=>{
    return(
        <button className="cart-btns" onClick={onClickAdd}>
            +
        </button>
    )
};
class PromoPopup extends Component{
    onClickAction = () => {
      if (this.props.promocodeItems) {
          this.props.addPromoItems(this.props.promocodeItems);
          this.props.closePopup();
      }else{
          this.props.history.push("/auth");
      }
    };
    componentDidMount() {
        document.addEventListener('click', this.props.closePopup, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.props.closePopup, false);
    }

    render() {
        return(
            <div className="promo-popup">
                <button className="close-popup" onClick={this.props.closePopup}>
                    <img src={deleteIcon} alt="close-popup"/>
                </button>
                <div className="title">Купон "{this.props.promocode}"</div>
                {this.props.message && <PromoText text={this.props.message}/>}
                {this.props.promocodeItems && <PromoGrid items={this.props.promocodeItems}/>}
                <div className="buttons">
                    <button className="cancel" onClick={this.props.closePopup}>Отменить</button>
                    {this.props.promocodeItems &&
                        <button className="action" onClick={this.onClickAction}>
                            Добавить
                        </button>
                    }
                    {!this.props.isAuthorised && !this.props.promocodeItems && !this.props.unauthorize_available &&
                            <button className="action" onClick={this.onClickAction}>
                                 Авторизоваться
                             </button>
                    }
                </div>
            </div>
        )
    }
}
const PromoGrid = ({items}) => (
    <div className="promo-grid-container">
        {items.map((item, index)=>
            <Fragment key={index}>
                <div className="cart-num">{index + 1}.</div>
                <div className="cart-name">{item.ProductName} {item.VariationName}</div>
                <div className="cart-cost">1 шт</div>
				<div className="cart-sum">{item.Price}<span className="ruble">&#8381;</span></div>
            </Fragment>)}
    </div>
);

const PromoText = ({text}) => (
    <div>
        {text}
    </div>
);

export default connect(state => ({sumAndCount: state.sumAndCount, token: state.token}), dispatch => ({updateCounter: ()=> {dispatch(updateSumAndCount())}}))(Cart);