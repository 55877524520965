import { createStore } from "redux";
import rootReducer from "../reducers/index";
import moment from "moment";

const sc = defCountAndSum();
const token = defToken();
const defaultState = {
    sumAndCount: {count: sc[1], sum: sc[0]},
    token
};

function defCountAndSum(){
    // Получаем изначальные показатели Суммы и Кол-ва
    let sum = 0;
    let count = 0;
    if(localStorage.getItem("sdvItems") === null){
        //пропускаем
    }else if((new Date() - Date.parse(JSON.parse(localStorage.getItem("sdvItems")).lastUpdate)) / 60000 > 180){
        localStorage.removeItem("sdvItems")
    }else{
        let obj = JSON.parse(localStorage.getItem("sdvItems"));
        obj.lastUpdate = new Date();
        sum = obj.sum;
        count = obj.items.length
    }
    return [sum, count]
}
function defToken() {
    if(localStorage.getItem("sdvToken") === null){
        return {};
    }else{
        let data = JSON.parse(localStorage.getItem("sdvToken"));
        if(moment().diff(data.CreationDate, 'days') < 2){
            return {
                "Token": data.Token,
                "UserId": data.UserId,
                "TokenLifetime": data.TokenLifetime,
                "CreationDate": data.CreationDate
            };
        }else{
            return {};
        }
    }
}

export default createStore(rootReducer, defaultState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());