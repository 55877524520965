import React from 'react';
import './PhoneNumber.css';

const PhoneNumber = () =>{
return (
    <div className="phone-number-container">
        <a className="phone-number" href="tel:+79605180803">+7 960 518 08 03 </a>
        <div className="phone-description">Звонок с 10:00 до 21:00</div>
    </div>
)};
export default PhoneNumber;