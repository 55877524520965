import React from 'react';
import { NavLink } from 'react-router-dom';
import './ItemOfNavigation.css';

const ItemOfNavigation = ({item, onClickSidebarItem})=>{
	return (
		<NavLink to={`/${item.Path}`} className="menu-item" key={item.Name} activeClassName="menu-item-active" onClick={onClickSidebarItem} >
              <div className="menu-item-icon">
                   <img src={item.Icon} alt={item.Name} />
              </div>
              <div className="menu-item-text">
                   {item.Name}
              </div>
         </NavLink>
    )
};
export default ItemOfNavigation;