import React, {useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import './Promotions.css';
import Page from "../../components/Page/Page";
import MaxiComboImg from '../../assets/images/MAXI-COMBO.jpg';
import MiniComboImg from '../../assets/images/MINI-COMBO.jpg';
import KidsComboImg from '../../assets/images/KIDS-COMBO.jpg';

const Promotions = () => {
    useEffect(() => {
        document.getElementById("promotionWrapper").scrollIntoView(true)
	})

	const stock_promo = 'https://api.semeinyidvorik.ru/wwwroot/icons/stocks.svg'
    return(
        <Page>
            <MetaTags>
                <title>Семейный Дворик | Акции</title>
            </MetaTags>
            <div className="typical-page">
                <div className="typical-page-wrapper" id="promotionWrapper">
                    <div className="typical-page-title universal-page-header-text">Акции</div>
					<div className="promo-grid">
						<div className="promo-grid-image">
							<img
								alt=""
								src={stock_promo}
								
							/>
							<h1 className="promotionTitleText">Скидка в День Рождения 15%!*</h1>
							<p className="promotionText happyBirthday">
								Воспользуйтесь акцией можно в течении 5 дней до Дня Рождения и 5 дней после, предъявив любой документ.
							</p>
						</div>

						<div className="promo-grid-image">
							<img
								alt=""
								src={MiniComboImg}
								
							/>
							<h1 className="promotionTitleText">MINI Комбо –  1205р.</h1>
							<p className="promotionText">
								<ul>
									<li>Пицца 25см в диаметре</li>
									<li>2 ролла</li>
									<li>Наггетсы 9 шт.</li>
									<li>Соус на выбор</li>
									<li>Напиток 0,5л.</li>
								</ul>
							</p>
						</div>

						<div className="promo-grid-image">
							<img
								alt=""
								src={MaxiComboImg}
								
							/>
							<h1 className="promotionTitleText">MAXI Комбо – 1980р.</h1>
							<p className="promotionText">
								<ul>
									<li>Пицца 38см в диаметре</li>
									<li>2 ролла</li>
									<li>2 ПаПахи</li>
									<li>2 напитка 0,5л.</li>
								</ul>
							</p>
						</div>

						<div className="promo-grid-image">
							<img
								alt=""
								src={KidsComboImg}
								
							/>
							<h1 className="promotionTitleText">KIDS Комбо – 870р.</h1>
							<p className="promotionText">
								<ul>
									<li>Пицца 25см в диаметре</li>
									<li>Порция фри</li>
									<li>Наггетсы 6 шт.</li>
									<li>Соус на выбор</li>
									<li>Напиток 0,5л.</li>
								</ul>
							</p>
						</div>

						<div className="promo-grid-image">
							<img
								alt=""
								src={stock_promo}
								
							/>
							<h1 className="promotionTitleText">Скидка при покупке нескольких пицц!**</h1>
							<p className="promotionText">
								При покупке 2х пицц 38 или 30 диаметра скидка 20% <br/>
									При покупке 3х пицц 38 или 30 диаметра скидка 25% <br/>
								При покупке 5и пицц 38 или 30 диаметра скидка 30% <br/>
								При покупке 10и пицц 38 или 30 диаметра скидка 35% <br/>
							</p>
						</div>

						<div className="promo-grid-image">
							<img
								alt=""
								src={stock_promo}
								
							/>
							<h1 className="promotionTitleText">Каждая 2я пицца за пол цены!***</h1>
							<p className="promotionText">
								Акция действует с 10:00 до 16:00 во все дни кроме выходных и праздничных,
								на каждую вторую пиццу того же диаметра скидка 50 %,
								скидка предоставляется на пиццу меньшей стоимости,
								акция действует с 31.10.2020г. по 31.07.2020г.
							</p>
						</div>

						<div className="promo-grid-image">
							<img
								alt=""
								src={stock_promo}
								
							/>
							<h1 className="promotionTitleText">Бонусная программа лояльности!</h1>
							<p className="promotionText">
								Зарегистрируйтесь в нашей программе лояльности и получайте бонусы, которыми Вы можете оплачивать покупки в нашем кафе.
								<ul>
									<li>для списания или зачисления бонусов назовите номер телефона.</li>
									<li>в виде баллов зачисляется 5% от стоимости покупки,</li>
									<li>минимальная сумма покупки для получения баллов 100р,</li>
									<li>1 балл = 1 рублю,</li>
									<li>баллами можно оплатить 100% покупки,</li>
									<li>начисленными баллами можно будет воспользоваться через сутки,</li>
									<li>неиспользованные баллы сгорают через 90 дней после последней покупки,</li>
									<li>на все пиццы 30 и 38 диаметра и закрытую пиццу Кальцоне начисляются баллы в двойном размере,</li>
									<li>баллы не начисляются при заказе доставки, на акционные товары, а так же на мороженое 33 пингвина.</li>
								</ul>
							</p>
						</div>
					</div>

					<div>
						<p>* - В одном чеке возможно использование только одной акции,
							скидки по акциям не суммируются, при использовании данной акции бонусы не начисляются.
							Акция действует на постоянной основе.</p>
						<p>** - Cкидки по акциям не суммируются,
							при использовании данной акции бонусы не начисляются,
							акция действует с 31.10.2020г. по 31.07.2020г.</p>
						<p>*** - Cкидки по акциям не суммируются,
							при использовании данной акции бонусы не начисляются.</p>
					</div>
				</div>
			</div>
        </Page>
    )
};

export default Promotions;
