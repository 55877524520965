import React, {Component, Fragment} from 'react';
import MetaTags from "react-meta-tags";
import './Universalpage.css';
import Page from "../../components/Page/Page";
import Loader from "../../components/Loader";
import mainService from "../../services/mainService";

export default class Universalpage extends Component {
    state = {
        title: "Семейный Дворик",
        description: "",
        keywords: "Семейный дворик, доставка еды, Калуга",
        component: <Loader/>
    };

    componentDidMount() {
        mainService.getUniversalPage(this.props.match.params.id).then((res)=>{
           let components = res.data.Data.map((item)=>{
               return this.returnComponent(item)
           });
           //res.data.Data // Массив
           this.setState({
              title: res.data.MetaTags.Title,
              description: res.data.MetaTags.Description,
              keywords: res.data.MetaTags.KeyWords,
              component: components
           });
        }).catch(()=>{
           this.props.history.push({pathname: "/404"});
        });
    }

    returnComponent = (item) =>{
        switch (item.Type) {
            case "title":
                return <div className="upage-title">{item.Content}</div>;
            case "mapDelivery":
                return <MapDelivery/>;
            case "mapLocation":
                return <MapLocation/>;
            case "h1":
                return <h1>{item.Content}</h1>;
            case "h2":
                return <h2>{item.Content}</h2>;
            case "h3":
                return <h3>{item.Content}</h3>;
            case "p":
                return <p>{item.Content}</p>;
            case "img":
                return <img src={item.Content} alt="Картинка"/>;
            case "ul":
                return <Ul array={item.Content}/>;
            case "AboutAndContacts":
                return <AboutAndContacts/>;
            default:
                return <div>Ошибка</div>
        }
    };

    render(){
        return (
            <Page>
                <MetaTags>
                    <title>{this.state.title}</title>
                    <meta name="description" content={this.state.description}/>
                    <meta name="keywords" content={this.state.keywords}/>
                </MetaTags>
                <div className="upage-main">
                    {this.state.component}
                </div>
            </Page>
        );
    }
}



const MapDelivery = () => {
  return(
      <iframe title="map"
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A844cc5b03798653add6dda246456bdcf6bd1e7b079ed1a2992dd6a495cd99483&amp;source=constructor"
                width="100%"
                height="300"
                frameBorder={0}
                className="upage-map"
    />
  )
};
const MapLocation = () => {
    return(
        <iframe title="map"
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A6f646d414cb70683a0a25a7b0001615f13cb2ac455a6c9f12b0e8492cfe693eb&amp;source=constructor"
                width="100%"
                height="300"
                frameBorder={0}
                className="upage-map"
    />
    )
};
const Ul = ({array}) => {
  return(
      <ul>
          {array.map((item)=>{return <li>{item}</li>})}
      </ul>
  )
};

const AboutAndContacts = () => (
    <div className="aboutAndContacts-container">
        <h2 className="aboutAndContacts-title">О компании</h2>
        <p>Кафе "Семейный дворик" - это кафе для всей семьи! Пицца, только из продуктов высокого качества,
            колбаски собственного производства - традиционный вкус колбасы наших предков.
            Бюргер ролл - утолит разбушевавшийся голод, а нежная кесадилья придется по вкусу
            любой изысканной даме, и не только. На десерт натуральное мороженое на сливочках
            "33 пингвина". Наши блюда мы делаем с любовью для Вас, добавляем только самые лучшие,
            самые качественные ингредиенты. Мы за натуральную, честную еду, без искусственных усилителей
            вкуса, консервантов, влагоудерживающих и других химических веществ. Мы непоколебимо уверенны
            в том, что качество имеет значение. Поэтому все продукты проходят строжайший отбор. Мы знаем,
            что наши гости достойны только самого лучшего. Мы всегда рады гостям и встречаем всех с искренней
            улыбкой. У нас дружная, слаженная команда. Мы делаем свою работу с удовольствием, поэтому в каждое
            блюдо вкладываем душу.
        </p>
        <h2>Контакты:</h2>
        <div>Адрес: г. Калуга, ул. Суворова, д. 113</div>
        <div>Режим работы: 10:00 – 21:00</div>
        <div>Контактый номер телефона: <a href="tel:+79605180803">+7 960 518 08 03 </a></div>
        <div>Социальные сети:</div>
        <div><a href="https://vk.com/public177743961">ВКонтакте</a></div>
        <div><a href="http://instagram.com/33pingvina_kaluga">Instagram</a></div>
        <div>Пожелания и предложения принимаются по адресу <a href="mailto:semejnyjdvorik@gmail.com">semejnyjdvorik@gmail.com</a></div>
    </div>
);