import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";

const PrivateRoute = ({component: Component, token, ...rest})=>(
    <Route {...rest}
           render={props=>token.Token !== undefined ?
               <Component {...props}/> : <Redirect to="/auth"/>
           }/>
);

export default connect(state => ({token: state.token}))(PrivateRoute)