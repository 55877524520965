import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import './ClassicItem.css';
import ModifiersOfItem from "../ModifiersOfItem/ModifiersOfItem";
import CheckMark from "../CheckMark/CheckMark";
import { Picture } from "react-responsive-picture";
import {updateSumAndCount} from "../../actions";
import {connect} from 'react-redux';

class ClassicItem extends Component {
    constructor(){
        super();
		this.state = {
			id: null,
			name: null,
			price: null,
			submited: false
		};
    }
    componentDidMount() {
        let imgSource = [];
        this.props.item.ProductVariations[0].Photos.forEach((item)=>{
           imgSource.push({srcSet: item.PhotoLink, type: item.PhotoType})
        });
		this.setState({
			id: this.props.item.ProductVariations[0].Id,
			price: this.props.item.ProductVariations[0].Price,
			count: this.props.item.ProductVariations[0].Count,
			unit: this.props.item.ProductVariations[0].Unit,
			name: this.props.item.Name,
			description: this.props.item.Description,
			img: imgSource,
		});
    }
    onChangeModifier = (currentSelectedModifier) =>{
        let imgSource = [];
        currentSelectedModifier.Photos.forEach((item)=>{
            imgSource.push({srcSet: item.PhotoLink, type: item.PhotoType})
        });
		this.setState({
			id: currentSelectedModifier.Id,
			price: currentSelectedModifier.Price,
			count: currentSelectedModifier.Count,
			unit: currentSelectedModifier.Unit,
			img: imgSource,

		});
    };
    onClickAdd = (id, sum)=>{
        this.setState({submited: true});
        setTimeout(()=>{this.setState({submited: false});}, 1000);

        if(localStorage.getItem("sdvItems") === null){
            let obj = {
              lastUpdate: new Date(),
              items: [id],
              sum: sum
            };
            localStorage.setItem("sdvItems", JSON.stringify(obj));
        }else{
            let obj = JSON.parse(localStorage.getItem("sdvItems"));
            obj.lastUpdate = new Date();
            obj.items.push(id);
            obj.sum += sum;
            localStorage.setItem("sdvItems", JSON.stringify(obj));
        }
        this.props.updateCounter();
    };

    render() {
        const modifiers = this.props.item.ProductVariations.length > 1 ?
            <ModifiersOfItem modifiers= { this.props.item.ProductVariations.sort((a, b) => a.Price - b.Price) } onChangeModifier={this.onChangeModifier} />
			: null;

        const btnInfo = this.state.submited ? <CheckMark/> : "Добавить";
        const {img} = this.state;

		return (
			<div className="ClassicItemContainer">
				<div className="CIimg">
					<LazyLoad height={250} offset={100} scroll={true} once>
						<Picture sources={img} alt={this.state.description} className="ClassicItemImg" />
					</LazyLoad>
				</div>

				<div className="ClassicItemName">{this.state.name}</div>
				<div className="ClassicItemDescription">{this.state.description}</div>

				{modifiers}

				<div className="product-card-cart">
					<div className="price-and-weight">
						<div className="product-card-price">
							{this.state.price}
							<span className="ruble">&#8381;</span>
						</div>
						<div className="product-card-weight">
							{this.state.count}
							{this.state.unit}
						</div>
					</div>

					{<button className={this.state.submited ? "product-card-add product-card-add-submited" : "product-card-add"}
						disabled={this.state.submited}
						onClick={() => this.onClickAdd(this.state.id, this.state.price, this.state.count, this.state.unit)}> {btnInfo} </button> }
				</div>
			</div>
		);
    }
}

export default connect(state => ({}), dispatch => ({updateCounter: ()=> {dispatch(updateSumAndCount())}}))(ClassicItem);