import React, { Component, Fragment } from 'react';
import Styles from './ContactPage.module.css';
import MetaTags from 'react-meta-tags';
import Footer from "../../components/Footer/Footer";
import classNames from "classnames";
import vk from '../../assets/icons/vk (1).svg';
import insta from '../../assets/icons/instagram.svg';
import Page from "../../components/Page/Page";

export default class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }
    render() {
        return (
            <Page>
				<MetaTags>
					<title> Контакты | Семейный дворик </title>
				</MetaTags>
                <div className="upage-main" ref={this.contentRef}>
					<AboutAndContacts/>
				</div>
            </Page>
		);
    }

    componentDidMount() {
        this.contentRef.current.scrollIntoView(true)
    }

    componentDidUpdate() {
        this.contentRef.current.scrollIntoView(true)
    };
}

const MapLocation = () => {
	return (
        <iframe title="map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A6f646d414cb70683a0a25a7b0001615f13cb2ac455a6c9f12b0e8492cfe693eb&amp;source=constructor"
            width="100%"
            height="300"
            frameBorder={0}
            className={Styles.upageMap}
		/>
	)
};


const AboutAndContacts = () => {
    const headerClassnames = classNames(Styles.upageTitle, Styles.universalPageHeaderText)
    const infoClassnames = classNames(Styles.contactsInfo, Styles.simpleText)
    return (
        <div className="upage-container">
            <MapLocation />
            <h2 className={headerClassnames}>О НАС</h2>
            <p className={infoClassnames}>Кафе "Семейный дворик" - это кафе для всей семьи!<br></br><br></br> Пицца, только из продуктов высокого качества,
                колбаски собственного производства - традиционный вкус колбасы наших предков.
                Бюргер ролл - утолит разбушевавшийся голод, а нежная кесадилья придется по вкусу
                любой изысканной даме, и не только. На десерт натуральное мороженое на сливочках
            "33 пингвина".<br></br><br></br> Наши блюда мы делаем с любовью для Вас, добавляем только самые лучшие,
                самые качественные ингредиенты. Мы за натуральную, честную еду, без искусственных усилителей
                вкуса, консервантов, влагоудерживающих и других химических веществ. Мы непоколебимо уверенны
                в том, что качество имеет значение. Поэтому все продукты проходят строжайший отбор. Мы знаем,
            что наши гости достойны только самого лучшего.<br></br><br></br> Мы всегда рады гостям и встречаем всех с искренней
                улыбкой. У нас дружная, слаженная команда. Мы делаем свою работу с удовольствием, поэтому в каждое
                блюдо вкладываем душу.
        </p>
            <h2 className={headerClassnames}>КОНТАКТЫ</h2>
            <div className={Styles.contacts}>
                <div className="simple-text">Адрес: г. Калуга, ул. Суворова, д. 113</div>
                <div className="simple-text">Режим работы: 10:00 – 21:00</div>
                <div className="simple-text">Контактый номер телефона: <a href="tel:+79605180803">+7 960 518 08 03 </a></div>
                <div className="simple-text">Социальные сети:</div><br></br>
                <div><a href="https://vk.com/public177743961"><img src={vk}></img>ВКонтакте</a></div>
                <div><a href="http://instagram.com/33pingvina_kaluga"><img src={insta}></img> Instagram</a></div><br></br>
                <div className="simple-text">Пожелания и предложения принимаются по адресу <a href="mailto:semejnyjdvorik@gmail.com">semejnyjdvorik@gmail.com</a></div>
            </div>
        </div>
    )
};