function token(state = {}, action) {
    switch(action.type){
        case "ADD_TOKEN":
            localStorage.setItem("sdvToken", JSON.stringify(action.payload));
            return {
                ...action.payload
            };
        case "DELETE_TOKEN":
            localStorage.removeItem("sdvToken");
            return {};
        default:
            return state;
    }
}

export default token;