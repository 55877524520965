import React from 'react';
import './Fail.css';
import errorIcon from '../../assets/icons/browser.svg';

const Fail = ()=>{
    return(
        <div className="fail-container">
            <img src={ errorIcon } alt="Error" className="errorIcon"/>
        </div>

    )
};

export default Fail;