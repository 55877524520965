import React from 'react';

const CheckMark = ()=>{
    return(
        <svg width="40" height="40" viewBox="0 0 48 48" fill="none"
             stroke="white" strokeWidth="3" strokeDasharray="100">
            <path id="check" d="M 12,22 L 22,31 L 36,13" strokeDashoffset="0">
                <animate attributeName="stroke-dashoffset"
                         from="100" to="0" repeatCount="1" dur="1s">
                </animate>
            </path>
        </svg>
    )
};

export default CheckMark;