import React, { Component } from "react";
import './DeliveryStep.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
//import BtnsBackOnward from "../BtnsBackOnward/BtnsBackOnward";
import Toast from "../../../components/Toast/Toast";
import {connect} from "react-redux";

class DeliveryStep extends Component{
    state = {typeDelivery: "delivery", timeDelivery: "soon"};

    disabledHours() {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 22, 23];
    }
    worksHours = [11,12,13,14,15,16,17,18,19,20,21];

    render() {
        const { typeDelivery, timeDelivery } = this.props;
        const today = moment(this.props.timeData);

        const twoWeeks = moment(today).add(14, 'days');

        // Костыль, и как бы нет
        function todayDisabledHours(){
            if(today.hour() >= 10 && today.hour() <= 22){
                let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 22, 23]
                    .concat([11,12,13,14,15,16,17,18,19,20,21].slice(0, [11,12,13,14,15,16,17,18,19,20,21].indexOf(today.hour() + 1)))
                    .sort((a, b)=> a - b);
                return arr;
            }
            return []
        }
        //


        return(
            <form className="order-form">
                {this.props.sumAndCount.count < 1 && <Toast text="Ваша корзина пуста!"/>}
                {this.props.deliveryWarning && <Toast text="Заполните все обязательные поля"/>}
                {this.props.sumAndCount.sum < 300 && <Toast text="Минимальная сумма заказа - 300 рублей"/>}
                {(timeDelivery === "soon" && (today.hour() >= 21 || today.hour() < 10)) && <Toast text="Кухня сейчас не работает, заказ будет приготовлен утром" className="toast-warning"/>}
                <div className="type-delivery">
                    <input type="radio" id="delivery" name="deliveryType" defaultChecked={typeDelivery === "delivery"} onChange={(e)=>this.props.changeDeliveryType("delivery", e)}/>
                    <label className="order-checkbox-text-active" htmlFor="delivery">Доставка</label>

                    <input type="radio" id="yourself" name="deliveryType" defaultChecked={typeDelivery === "pickup"} onChange={(e)=>this.props.changeDeliveryType("pickup", e)}/>
                    <label className="order-checkbox-text-active" htmlFor="yourself">Самовывоз</label>
                </div>
                {typeDelivery === "delivery" && <TypeDelivery
                    onWriteStreet = {this.props.onWriteStreet}
                    onWriteBuilding = {this.props.onWriteBuilding}
                    onWriteKorpus = {this.props.onWriteKorpus}
                    onWriteApartment = {this.props.onWriteApartment}
                    onWriteFloor = {this.props.onWriteFloor}
                    onWriteEntrance = {this.props.onWriteEntrance}
                />}
                {typeDelivery === "pickup" && <TypePickUp/>}
                <div className="time-delivery">
                    <input type="radio" id="soon" name="deliveryTime" defaultChecked={timeDelivery === "soon"} onChange={(e)=>this.props.changeDeliveryTime("soon", e)}/>
                    <label htmlFor="soon" className="order-label order-checkbox-text-active">В ближайшее время</label>

                    <input type="radio" id="late" name="deliveryTime" defaultChecked={timeDelivery === "late"} onChange={(e)=>this.props.changeDeliveryTime("late", e)}/>
                    <label htmlFor="late" className="order-label order-checkbox-text-active">В определенное время</label>
                    {timeDelivery === "late" && <div className="delivery-step-time">
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            format='DD/MM/YYYY'
                            value={moment(this.props.dayOfDelivery).format('DD/MM/YYYY')}
                            placeholder={`${this.props.dayOfDelivery}`}
                            onDayChange={(day)=> {this.props.onChangeDay(day)}}
                            dayPickerProps={{
                                months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',],
                                weekdaysLong: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота',],
                                weekdaysShort: ['Вс', 'Пн', 'Вlт', 'Ср', 'Чт', 'Пт', 'Сб'],
                                firstDayOfWeek: 1,
                                labels: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' },
                                disabledDays: {
                                    before: today.hour() < 21 ? today.toDate() : moment(today).add(1, 'days').toDate(), //today.toDate()
                                    after: twoWeeks.toDate()
                                },
                            }}
                        />
                        <TimePicker
                            showSecond={false}
                            defaultValue={this.props.timeOfDelivery}
                            disabledHours={moment(this.props.dayOfDelivery).date() === today.date() ? todayDisabledHours : this.disabledHours}
                            minuteStep={10}
                            className="timepicker"
                            onChange = {(time)=>this.props.onChangeHours(time)}
                        />
                    </div>}

                </div>
                {/*<label className="order-textarea">
                    Комментарий:<br/>
                    <textarea name="comment" placeholder="Комментарий" onChange={this.props.onWriteComment}>
                    </textarea>
                </label>
                <BtnsBackOnward
                    onClickBack = {this.props.onClickBack}
                    onClickOnward = {this.props.onClickOnward}
                    step = {1}
                />*/}
            </form>
        )
    }
}

const TypeDelivery = ({onWriteStreet, onWriteBuilding, onWriteKorpus, onWriteApartment, onWriteFloor, onWriteEntrance})=>{
    return(
        <div className="order-adress">
            <label className="order-input-text order-checkbox-text-active">
                Город
                <input className="order-checkbox-text-active" type="text" value="Калуга" disabled={true}/>
            </label>

            <label className="order-input-text order-checkbox-text-active">
                Улица<sup>*</sup>
                <input type="text" placeholder="Суворова" onChange={onWriteStreet}/>
            </label>

            <label className="order-input-text order-checkbox-text-active">
                Дом<sup>*</sup>
                <input type="text" placeholder="113" onChange={onWriteBuilding}/>
            </label>

            <label className="order-input-text order-checkbox-text-active">
                Корпус
                <input type="text" placeholder="7" onChange={onWriteKorpus}/>
            </label>
            
            <label className="order-input-text order-checkbox-text-active">
                Подъезд<sup>*</sup>
                <input type='text' placeholder="1" onChange={onWriteEntrance}/>
            </label>

            <label className="order-input-text order-checkbox-text-active">
                Квартира<sup>*</sup>
                <input type="text" placeholder="10" onChange={onWriteApartment}/>
            </label>

            <label className="order-input-text order-checkbox-text-active">
                Этаж<sup>*</sup>
                <input type="text" placeholder="1" onChange={onWriteFloor}/>
            </label>
        </div>
    )
};

const TypePickUp = ()=>{
    return(
        <div className="order-adress">
            <div className="select-wrapper">
                <select>
                    <option>Калуга, Суворова 113</option>
                </select>
                <div className="select-arrow"></div>
                <div className="select-arrow"></div>
            </div>
        </div>
    )
};

export default connect(state => ({sumAndCount: state.sumAndCount}))(DeliveryStep);