function user(state = {}, action) {
    switch(action.type){
        case "USER_ADD_PHONE_NUMBER":
            return {
                ...state,
                phone: action.payload
            };
        default:
            return state;
    }
}

export default user;