import React, { Component } from 'react';
import './Navigation.css';
import ItemOfNavigation from './ItemOfNavigation/ItemOfNavigation';
import mainService from '../../../services/mainService';

export default class Navigation extends Component {
	constructor() {
		super();
		this.state = {
			data: null,
			error: false,
			loading: true
		};
	}

	componentDidMount() {
		mainService.getNavigationMenu().then((responce) => {
			this.setState({ data: responce.data, loading: false });
		}).catch(() => { this.setState({ error: true, loading: false }); });
	}

	render() {
		const { data, loading, error } = this.state;
		const { isNotVisibleMobileSidebar } = this.props;

		let navigation = !(loading || error) ?
			data.map((item) => {
				return (
					<ItemOfNavigation item={item} key={item.Name} onClickSidebarItem={this.props.onClickNavItem} />
				);
			}) : null;

		return (
			<nav className="nav-row">
				{ navigation }
			</nav>
		);
	}
}