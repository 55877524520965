import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Main from './pages/Main/Main';
import Promotions from './pages/Promotions/Promotions';
import Cart from "./pages/Cart/Cart";
import Order from "./pages/Order/Order";
import Auth from "./pages/Auth/Auth";

import PublicOffer from "./pages/OfferPage/OfferPage";
import Terms from "./pages/OfferPage/Terms/Terms";
import PrivacyPolicy from "./pages/OfferPage/PrivacyPolicy/PrivacyPolicy";
import Offer from "./pages/OfferPage/Offer/Offer";
import ContactPage from "./pages/ContactPage/ContactPage";
import DeliveryPage from "./pages/DeliveryPage/DeliveryPage";

import Confirmation from "./pages/Confirmation/Confirmation";
import Universalpage from "./pages/Universalpage/Universalpage";
import Notfound from "./pages/Notfound/Notfound";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import MyOrders from "./pages/MyOrders/MyOrders";
import MetaTags from 'react-meta-tags';

export default class App extends Component {
    constructor(){
        super();
        this.state = {
            isNotVisibleMobileSidebar: true
        }
    }

    onClickHamburgerMenu = (status)=>{
        this.setState({isNotVisibleMobileSidebar: status});
    };
	onClickSidebarItem = () => {
		if (this.state.isNotVisibleMobileSidebar === false) {
			this.setState({ isNotVisibleMobileSidebar: true });
		}
	};
    render() {
        return(
            <Fragment>
                <MetaTags>
                    <meta name="format-detection" content="telephone=no"></meta>
                </MetaTags>
                        <Switch>
                            <Route exact path='/' component={ Main }/>
                            <Route path='/menu/:id' component={ Main }/>
                            <Route path='/pages/stocks' component={ Promotions }/>
                            <Route path="/cart" component={ Cart }/>
                            <PrivateRoute path="/order" component={ Order }/>
                            <PrivateRoute path="/myorders" component={ MyOrders }/>
							<Route path="/auth" component={Auth} />

							<Route path="/pages/offer" component={PublicOffer}/>
							<Route path="/pages/PP" component={PrivacyPolicy} />
							<Route path="/pages/UA" component={Terms} />
							<Route path="/pages/PO" component={Offer} />
							<Route path="/pages/contacts" component={ContactPage} />
							<Route path="/pages/about" component={ContactPage} />
							<Route path="/pages/delivery" component={DeliveryPage} />

							<Route path="/confirmation" component={Confirmation} />
                            <Route path="/page/:id" component={ Universalpage }/>
                            <Route path="/404" component={ Notfound }/>
                            <Route component={ Notfound }/>
                        </Switch>
             </Fragment>
        )
    }
}

