import React, { Component } from 'react';
import './Footer.css';
import { Link } from "react-router-dom";
import Vk from '../../assets/icons/vk-social.svg';

export default class Footer extends Component {
    render() {
        const year = new Date();
		return (
			<footer>
				<div className="footer-top">
					<span className="footer-link"> <Link to="/pages/delivery">  Доставка </Link> </span>
					<span className="footer-link"> <Link to="/pages/stocks">  Акции </Link> </span>
					<span className="footer-link"> <Link to="/pages/offer">  Правовая информация </Link> </span>
					<span className="footer-link"> <Link to="/pages/contacts">  Контакты </Link> </span>
					<span className="footer-link"> <Link to="/pages/about">  О Компании </Link> </span>
				</div>

				<div className="footer-bottom">
					<span> <a href="https://vk.com/public177743961" className="footer-social"> <img src={Vk} alt='Вконтакте' /> </a> </span>
					<span className="footer-copyright"> Семейный Дворик © {year.getFullYear()} </span>
				</div>

				<div className="footer-side">
					<div>
						<div className="footer-number-orange-block">Звонок с 10:00 до 21:00</div>
						<div className="footer-number-phone"><a className="footer-phone-number" href="tel:+79605180803">+7 960 518 08 03 </a></div>
					</div>
				</div>
			</footer>
		);
    }
}