import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import mainService from '../../services/mainService';
import Loader from "../../components/Loader";
import Fail from "../../components/Fail";
import CategoryContainer from "../../components/CategoryContainer/CategoryContainer";
import './Main.css';
import Page from "../../components/Page/Page";
import PromotionsBanner from '../../components/PromotionsBanner/PromotionsBanner';

export default class Main extends Component{
    refsDOMCategories = {};
    constructor(){
        super();
        this.state = {
            categories: null,
            error: false,
            loading: true
        }
    }
    componentDidMount() {
        mainService.getAllMenuCategories().then((responce)=>{
            let categories = responce.data.sort((a, b) => b.Priority - a.Priority);
            this.setState({ categories: categories, loading: false});
        }).then(()=>{
            setTimeout(()=>{this.scrollTo()},300);
        }).catch(()=>{this.setState({loading: false, error: true})});
    }
    componentDidUpdate() {
        this.scrollTo();
        if(this.props.match.params.id === undefined)
           window.scrollTo(0, 0);
    }

    scrollTo = () => {
        if("/"+this.props.match.params.id in this.refsDOMCategories)
            this.refsDOMCategories["/"+this.props.match.params.id].scrollIntoView(true);
    };

    render(){
        const {categories, loading, error} = this.state;
        const spinner = loading ? <Loader/> : null;
        const err = error ? <Fail/>: null;
        const category = !(loading || error) ? categories.map((item) => {
            return (
                    <CategoryContainer refs={(node) => this.refsDOMCategories[item.Path] = node} id={item.Id} name={item.Name} key={item.Id} />
                )
        }) : null;

		return (
			<Page>
				<MetaTags>
					<title>Семейный Дворик | Кафе для всей семьи</title>
                </MetaTags>
                <Fragment>
                    <PromotionsBanner />
                    {spinner || err || category}
                </Fragment>
			</Page>
		);
    } 
}