import React, {Component} from 'react';
import './CategoryContainer.css';
import mainService from "../../services/mainService";
import Loader from "../Loader";
import Fail from "../Fail";
import ClassicItem from "../ClassicItem/ClassicItem";


export default class CategoryContainer extends Component{
    constructor(){
        super();
        this.state = {
            positions: null,
            header: null,
            error: false,
            loading: true
        }
    }
    componentDidMount(){
        mainService.getMenuCategory(this.props.id).then((res)=>{
            let data = res.data;
            let header = data.Name;
            let positions = data.Products.sort((a, b) => b.Priority - a.Priority);
            this.setState({positions: positions, header: header, error: false, loading: false});
        }).catch(()=>{this.setState({loading: false, error: true})});
    }

    render(){
        const {positions, header, loading, error} = this.state;
        const spinner = loading ? <Loader/> : null;
        const err = error ? <Fail/>: null;
        const items = !(loading || error) ? positions.map((item)=>{
            return (
                <ClassicItem key={"item " + item.Id} item={item}/>
            )
        }) : null;

        return(
            <div ref={this.props.refs} className={"categoryContainer"}>
                <h2 className="category-products-title universal-page-header-text">{header}</h2>
                <div className="category-products-grid">
                    
                    {spinner || err || items}
                </div>
            </div>
        )
    }

}