import React, { Component } from 'react';
import './OfferPage.css';
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import Page from "../../components/Page/Page";

export default class OfferPage extends Component {
	render() {
		return (
			<Page>
				<MetaTags>
					<title> Правовая информация | Семейный дворик </title>
				</MetaTags>

				<div className="offer-container">
					<div className="offer-main">
						<h1> Правовая информация </h1>

						<Link to="/pages/PO"> Публичная оферта </Link>
						<Link to="/pages/UA"> Пользовательское соглашение </Link>
						<Link to="/pages/PP"> Политика конфиденциальности </Link>
					</div>				
				</div>
			</Page>
		);
	}
}