import React from 'react';
import "./Toast.css";

const Toast = ({text, className}) => {
    return(
        <div className={`toast ${className}`} >
            {text}
        </div>
    )
};
export default Toast;