import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import './MyOrders.css';
import Page from "../../components/Page/Page";
import mainService from "../../services/mainService";
import {connect} from "react-redux";
import Loader from "../../components/Loader";
import Fail from "../../components/Fail";
import moment from "moment";

class MyOrders extends Component{
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }

    state = {loading: true, error: false, order: []};
    componentDidMount() {
        mainService.getUserOrders(this.props.token.Token)
            .then((res)=>{
                console.log(res.data);
            this.setState({order: res.data, loading: false, error: false})
        }).catch(()=> this.setState({loading: false, error: true}))

        this.contentRef.current.scrollIntoView(true)
    }

    componentDidUpdate() {
        this.contentRef.current.scrollIntoView(true)
    };

    render() {
        const  {loading, error, order} = this.state;
        const spinner = loading ? <Loader/> : null;
        const err = error ? <Fail/>: null;
        const items = !(loading || error) ? order.map((item, index)=>{
            return (
                <MyOneOrder address={ item.DeliveryInfo.DeliveryType.Id === 1 ? `${item.Address.Town}, ${item.Address.Streat}
                ${item.Address.Building}, ${item.Address.Block !== 0 ? item.Address.Block+", ": ""} кв.${item.Address.Apartment}` : ""}
                            date={item.Date} status={item.Status} data={item.OrderDetails} fullprice={item.FullPrice} key={index}/>
            )
        }) : null;
        return(
            <Page>
                <MetaTags>
                    <title>Мои заказы | Семейный Дворик</title>
                </MetaTags>
                <div className="my-orders" ref={this.contentRef}>
                    <div className="my-orders-content">
                        <h2 className="upage-title universal-page-header-text">МОИ ЗАКАЗЫ</h2>
                        {spinner || err || items}
                    </div>
                </div>
            </Page>
        )
    }
}

const MyOneOrder = ({address, date, status, data, fullprice}) => {
  return(
      <div className="my-order-component">
          <div className="my-orders-info my-orders-grid-info-text">
              {address && <div>Адрес: {address}</div>}
              <div>Оформлен: {moment(date).format("DD/MM/YYYY HH:mm")}</div>
              <div class="status">Статус: {status}</div>
          </div>
          <div className="my-orders-grid my-orders-grid-text">
              {data.map((item)=> (
                  <Fragment  key={"a" + item.Id}><div>{item.ProductName}</div><div>{item.Count} шт.</div><div className="my-orders-cost">{item.Price}Р</div></Fragment>
              ))}
              <hr className="grid-line"></hr>
              <div className="my-orders-cost-full my-orders-itogo">Итого: {fullprice}Р</div>
          </div>
      </div>
  )
};

const mapStateToProps = state => ({ token: state.token });

export default connect(mapStateToProps)(MyOrders)