import React from 'react';
import './PromotionsBanner.css';
import MAXICOMBO from '../../assets/images/MAXI-COMBO.jpg';
import MINICOMBO from '../../assets/images/MINI-COMBO.jpg';
import KIDSCOMBO from '../../assets/images/KIDS-COMBO.jpg';
import Note from '../Note/Note';

const PromotionsBanner = () => {
    return (
        <div className="comboItemContainer">
            <h2 className='universal-page-header-text typical-page-title'>КОМБО</h2>
         {/*  <Note /> */}
            <div className='combo-list'>
                <div className='item-combo'>
                    <img src={KIDSCOMBO} className='img-item-combo' />
                    <h3>KIDS КОМБО за 870р.</h3>
                    <p>Пицца 25см в диаметре, соус на выбор, напиток 0,5л.,</p>
                    <p>наггетсы 6 шт., порция фри*</p>
                    <p className='comment'>*Вы можете выбрать любую пиццу 25 диаметра, соус и напиток 0,5л. имеющиеся в ассортименте кафе, для этого укажите выбранные Вами позиции в комментарии к заказу или непосредственно по телефону  8 (960) 518-08-03.</p>
                </div>
                <div className='item-combo'>
                    <img src={MINICOMBO} className='img-item-combo' />
                    <h3>MINI КОМБО за 1205р.</h3>
                    <p>Пицца 25см в диаметре, 2 ролла, наггетсы 9 шт.,</p>
                    <p>соус на выбор, напиток 0,5л.*</p>
                    <p className='comment'>*Вы можете выбрать любую пиццу 25 диаметра, роллы, соус и напиток 0,5л. имеющиеся в ассортименте кафе, для этого укажите выбранные Вами позиции в комментарии к заказу или непосредственно по телефону  8 (960) 518-08-03.</p>
                </div>
                <div className='item-combo'>
                    <img src={MAXICOMBO} className='img-item-combo' />
                    <h3>MAXI КОМБО за 1980р.</h3>
                    <p>Пицца 38см в диаметре, 2 ролла,</p>
                    <p>2 ПаПахи, 2 напитка 0,5л.*</p>
                    <p className='comment'>*Вы можете выбрать любую пиццу 38 диаметра, роллы, ПаПахи, соус и напиток 0,5л. имеющиеся в ассортименте кафе, для этого укажите выбранные Вами позиции в комментарии к заказу или непосредственно по телефону  8 (960) 518-08-03.</p>
                </div>
            </div>
        </div>
    );
};

export default PromotionsBanner;