import React, { Component } from 'react';
import './Sidebar.css';
import mainService from '../../services/mainService.js';
import Loader from '../Loader';
import Fail from '../Fail';
import ItemOfNavigation from './ItemOfNavigation/ItemOfNavigation';

export default class Sidebar extends Component{
    constructor(){
        super();
		this.state = {
			data: null,
			error: false,
			loading: true
		};
    }
    componentDidMount(){
		mainService.getNavigationMenu().then((res) => {
			this.setState({ data: res.data, loading: false });
		}).catch(() => {
			this.setState({ error: true, loading: false });
		});
    }
    render(){
    const {data, loading, error} = this.state;
    const { isNotVisibleSidebar } = this.props;
    
    const spinner = loading ? <Loader/> : null;
    const err = error ? <Fail/>: null;
	const navigation = !(loading || error) ?
		data.map((item) => { return (<ItemOfNavigation item={item}
                                                       key={item.Name}
                                                       onClickSidebarItem={this.props.onClickSidebarItem} />)
		}) : null;

		return (
			<nav className={`navigation ${isNotVisibleSidebar ? null : "sidebar-visible"}`}>
				{spinner || err || navigation}
			</nav>
		);
    }
}
