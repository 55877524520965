function sumAndCount(state = {}, action) {
    switch(action.type){
        case "UPDATE_SUM_AND_COUNT":
            let count;
            let sum;
            if(localStorage.getItem("sdvItems") === null){
                count = 0;
                sum = 0;
            }else{
                let obj = JSON.parse(localStorage.getItem("sdvItems"));
                count = obj.items.length;
                sum = obj.sum;
            }
            return {sum: sum, count: count};
        default:
            return state;
    }
}

export default sumAndCount;