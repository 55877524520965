import React, {Component} from 'react';
import './PaymentStep.css';
//import BtnsBackOnward from "../BtnsBackOnward/BtnsBackOnward";
import Toast from "../../../components/Toast/Toast";
import {connect} from "react-redux";

class PaymentStep extends Component{
    render() {
        return(
            <form className="order-form">
                {this.props.warning && <Toast text="Ваша корзину пуста!"/>}
                {this.props.sumAndCount.sum < 300 && <Toast text="Минимальная сумма заказа - 300 рублей"/>}
                <div className="payment-checkbox-container">
                    <div>
                        <input type="radio" id="online" name="payment" disabled={true} onChange={()=>this.props.onChangePaymentType(1)}/>
                        <label className="order-checkbox-text" htmlFor="online">Банковской картой (Недоступно)</label>
                    </div>

                    <div>
                        <input type="radio" id="card" name="payment" defaultChecked onChange={()=>this.props.onChangePaymentType(2)}/>
                        <label className="order-checkbox-text-active" htmlFor="card">Банковской картой (Курьеру)</label>
                    </div>
                    <div>
                        <input type="radio" id="cash" name="payment" onChange={()=>this.props.onChangePaymentType(3)}/>
                        <label className="order-checkbox-text-active" htmlFor="cash">Наличными</label>
                    </div>
                </div>
                {/*<BtnsBackOnward
                    onClickBack = {this.props.onClickBack}
                    onClickOnward = {this.props.onClickOnward}
                    step = {this.props.step}/>*/}
            </form>
        )
    }
}
export default connect(state => ({sumAndCount: state.sumAndCount}))(PaymentStep);