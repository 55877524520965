import React, {Component} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import './Page.css'

class Page extends Component{
	constructor() {
		super();
		this.state = {
			isNotVisibleSidebar: true
		};
	}

	onClickHamburgerMenu = (status) => {
		this.setState({ isNotVisibleSidebar: status });
		console.log(status);
	};
	onClickSidebarItem = () => {
		console.log("�����");
		if (this.state.isNotVisibleSidebar === false) {
			this.setState({ isNotVisibleSidebar: true });
			console.log("��������");
		}
	};

	render() {
        return(
            <div>
				<Header onClickHamburgerMenu={this.onClickHamburgerMenu} isNotVisibleSidebar={this.state.isNotVisibleSidebar}/>
                <div className="horizontal-container">
					<Sidebar isNotVisibleSidebar={this.state.isNotVisibleSidebar} onClickSidebarItem={this.onClickSidebarItem}/>
                    <main>
                        {this.props.children}
                    </main>
				</div>
				<Footer />
            </div>
        )
    }
}
export default Page;