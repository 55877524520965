import axios from "axios";

// export default class mainService {
// 	static getMenuCategory(categoryId) {
// 		return axios.get(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/Category/GetByIdWithProducts/" +
// 				categoryId
// 		);
// 	}
// 	static getAllMenuCategories() {
// 		return axios.get(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/Category/GetAll"
// 		);
// 	}
// 	static getFreeCode(phone) {
// 		return axios.post(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/User/TestCodeGeneration",
// 			{ PhoneNumber: phone }
// 		);
// 	}
// 	static sendCodeToUser(phone) {
// 		return axios.post(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/User/SendSMSCodeToUser",
// 			{ PhoneNumber: phone }
// 		);
// 	}
// 	static getNavigationMenu() {
// 		return axios.get(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/MenuCategory/GetAll"
// 		);
// 	}
// 	static getCartItems(items) {
// 		return axios.post(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/Order/UpdateOrderDetail",
// 			items
// 		);
// 	}
// 	static authenticate(data) {
// 		return axios.post(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/User/Autorise",
// 			{ Code: data.code, User: { PhoneNumber: data.phone } }
// 		);
// 	}
// 	static getUserData(token) {
// 		return axios.get(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/User/GetData",
// 			{ headers: { Authorization: `Bearer ${token}` } }
// 		);
// 	}
// 	static makeOrder(
// 		token,
// 		name,
// 		street,
// 		building,
// 		block,
// 		apartment,
// 		floor,
// 		entrance,
// 		deliveryType,
// 		deliveryDate,
// 		comment,
// 		paymentType,
// 		items,
// 		email,
// 		deliveryTimeType
// 	) {
// 		return axios.post(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/Order/Make",
// 			{
// 				User: {
// 					Name: name,
// 					Email: email,
// 				},
// 				Address: {
// 					Town: {
// 						Name: "Калуга",
// 					},
// 					Streat: {
// 						Name: street,
// 					},
// 					Building: {
// 						Name: building,
// 					},
// 					Block: {
// 						Name: block,
// 					},
// 					Apartment: {
// 						Name: apartment,
// 					},
// 					Floor: floor,
// 					Entrance: entrance,
// 				},
// 				DeliveryInfo: {
// 					DeliveryTypeId: deliveryType === "delivery" ? 1 : 2,
// 					DeliveryTimeTypeId: deliveryTimeType === "soon" ? 1 : 2,
// 					DeliveryDate: deliveryDate,
// 					Commentary: comment,
// 				},
// 				PaymentInfo: {
// 					PaymentTypeId: paymentType,
// 				},
// 				OrderDetails: items, //  array
// 			},
// 			{ headers: { Authorization: `Bearer ${token}` } }
// 		);
// 	}
// 	static getUniversalPage(url) {
// 		return axios.post(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/Page/GetByUrl",
// 			{ Url: url }
// 		);
// 	}
// 	static getSlider() {
// 		return axios.get(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/SliderPhoto/GetAll"
// 		);
// 	}

// 	static getUserOrders(token) {
// 		return axios.post(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/Order/GetAllByUserData",
// 			null,
// 			{ headers: { Authorization: `Bearer ${token}` } }
// 		);
// 	}
// 	static usePromocode(token, promo) {
// 		return axios.post(
// 			"http://apifc-test.u0486358.plsk.regruhosting.ru/api/Stock/UseStockByPromocode",
// 			{ Code: promo },
// 			{ headers: { Authorization: `Bearer ${token}` } }
// 		);
// 	}
// }

export default class mainService {
	static getMenuCategory(categoryId) {
		return axios.get(
			"https://api.semeinyidvorik.ru/api/Category/GetByIdWithProducts/" +
				categoryId
		);
	}
	static getAllMenuCategories() {
		return axios.get("https://api.semeinyidvorik.ru/api/Category/GetAll");
	}
	static getFreeCode(phone) {
		return axios.post(
			"https://api.semeinyidvorik.ru/api/User/TestCodeGeneration",
			{ PhoneNumber: phone }
		);
	}
	static sendCodeToUser(phone) {
		return axios.post(
			"https://api.semeinyidvorik.ru/api/User/SendSMSCodeToUser",
			{ PhoneNumber: phone }
		);
	}
	static getNavigationMenu() {
		return axios.get("https://api.semeinyidvorik.ru/api/MenuCategory/GetAll");
	}
	static getCartItems(items) {
		return axios.post(
			"https://api.semeinyidvorik.ru/api/Order/UpdateOrderDetail",
			items
		);
	}
	static authenticate(data) {
		return axios.post("https://api.semeinyidvorik.ru/api/User/Autorise", {
			Code: data.code,
			User: { PhoneNumber: data.phone },
		});
	}
	static getUserData(token) {
		return axios.get("https://api.semeinyidvorik.ru/api/User/GetData", {
			headers: { Authorization: `Bearer ${token}` },
		});
	}

	static makeOrder(
		token,
		name,
		street,
		building,
		block,
		apartment,
		floor,
		entrance,
		deliveryType,
		deliveryDate,
		comment,
		paymentType,
		items,
		email,
		deliveryTimeType
	) {
		return axios.post(
			"https://api.semeinyidvorik.ru/api/Order/Make",
			{
				User: {
					Name: name,
					Email: email,
				},
				Address: {
					Town: {
						Name: "Калуга",
					},
					Streat: {
						Name: street,
					},
					Building: {
						Name: building,
					},
					Block: {
						Name: block,
					},
					Apartment: {
						Name: apartment,
					},
					Floor: floor,
					Entrance: entrance,
				},
				DeliveryInfo: {
					DeliveryTypeId: deliveryType === "delivery" ? 1 : 2,
					DeliveryTimeTypeId: deliveryTimeType === "soon" ? 1 : 2,
					DeliveryDate: deliveryDate,
					Commentary: comment,
				},
				PaymentInfo: {
					PaymentTypeId: paymentType,
				},
				OrderDetails: items, //  array
			},
			{ headers: { Authorization: `Bearer ${token}` } }
		);
	}

	static getUniversalPage(url) {
		return axios.post("https://api.semeinyidvorik.ru/api/Page/GetByUrl", {
			Url: url,
		});
	}

	static getSlider() {
		return axios.get("https://api.semeinyidvorik.ru/api/SliderPhoto/GetAll");
	}

	static getUserOrders(token) {
		return axios.post(
			"https://api.semeinyidvorik.ru/api/Order/GetAllByUserData",
			null,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
	}
	static usePromocode(token, promo) {
		return axios.post(
			"https://api.semeinyidvorik.ru/api/Stock/UseStockByPromocode",
			{ Code: promo },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
	}
}
