import React, {Component} from 'react';
import './Order.css';
import MetaTags from 'react-meta-tags';
import DeliveryStep from "./DeliveryStep/DeliveryStep";
import ContactStep from "./ContactStep/ContactStep";
import Bill from "./Bill/Bill";
import PaymentStep from "./PaymentStep/PaymentStep";
import {connect} from "react-redux";
import mainService from "../../services/mainService";
import moment from "moment";
import BtnsBackOnward from "./BtnsBackOnward/BtnsBackOnward";
import Page from "../../components/Page/Page";
import bg from '../../assets/images/bgimg.png';


class Order extends Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }
    state = {
        step: 1,
        typeDelivery: "delivery",
        timeDelivery: "soon",
        dayOfDelivery: moment(new Date()),
        timeOfDelivery: moment(new Date()).add(1, 'hours'),
        userData: {PhoneNumber: "", Name: ""},
        paymentType: 2, // TODO: Изменить на 1 когда будет оплата картой,
        warning: false,
        deliveryWarning: false,
        contactWarning: false,
        buttonsDisabled: false 
    };
    componentDidMount() {
       if(this.props.token.token === undefined){
           this.props.history.push({pathname:"/auth", state:{redirect: "/order"}});
       }else{
           mainService.getUserData(this.props.token.token).then((res)=>{
               let name = res.data.UserData.Name || "";
               this.setState({
                   userData: res.data.UserData,
                   name: name,
                   email: res.data.UserData.Email,
                   timeData: res.data.TimeData.Time,
                   dayOfDelivery: moment(new Date(res.data.TimeData.Time)).hour() < 21 ? moment(new Date(res.data.TimeData.Time)) : moment(new Date(res.data.TimeData.Time)).add( 1, 'day'),
                   timeOfDelivery: moment(new Date(res.data.TimeData.Time)).hour() >= 11 && moment(new Date(res.data.TimeData.Time)).hour() < 21 ? moment(new Date(res.data.TimeData.Time)).add(1, 'hours') : moment(new Date(res.data.TimeData.Time)).hour(11),
                   deliveryData: res.data.DeliveryData
               })
           }).catch((res)=>{
               this.props.deleteToken();
               this.props.history.push({pathname:"/auth", state:{redirect: "/order"}});
           })
       }
    }
    onClickBack = () => {
      if(this.state.step ===1){
          // nothink
      }else if(this.state.step === 2){
          this.setState({step: 1})
      }else if(this.state.step === 3){
          this.setState({step: 2, warning: false})
      }
    };

    checkProps = () => {
        let b = true
        if (this.state.name === "" || this.state.name === undefined) {
            this.setState({ contactWarning: true });
            b = false
        }
        else {
            this.setState({ contactWarning: false });
        } 
        if (!(this.state.street > 0 && this.state.building > 0 && this.state.building > 0 && this.state.apartment > 0) &&
            !(this.state.street !== undefined && this.state.building !== undefined && this.state.building !== undefined && this.state.apartment !== undefined)) {
            this.setState({ deliveryWarning: true });
            b = false
        }
        else {
            this.setState({ deliveryWarning: false });
        }
        return b;
    }

    onClickOnward = () => {
        
       /* if(this.state.step ===1){
            if(this.props.count.sumAndCount > 0 && this.props.sum.sumAndCount > 300){
                if((this.state.street && this.state.building &&
                    this.state.apartment) || this.state.typeDelivery !== "delivery"
                ){
                    this.setState({step: 2});
                    this.setState({warning: false})
                }else{
                    this.setState({warning: true})
                }
            }
        }else if(this.state.step === 2){
            if(this.state.name !== "" && this.state.name !== undefined){
                this.setState({step: 3});
                this.setState({warning: false})
            }else{
                this.setState({warning: true})
            }
        }else*/
        this.setState({ step: 3 });
        //alert(this.state.step)
        if (this.props.count.sumAndCount > 0 && this.props.sum.sumAndCount > 300) {
            let data = [];
            let obj = this.getIDs();
            if(obj){
                for (let key in obj){
                    data.push({id: key, count: obj[key]});
                }
                // Отправить данные на сервер
                let dataForServer = [];
                data.forEach((item)=>{
                    dataForServer.push({
                        "ProductVariationId" : item.id,
                        "Count" : item.count
                    })
                });

                mainService.makeOrder(
                    this.props.token.token,
                    this.state.name,
                    this.state.street,
                    this.state.building,
                    this.state.korpus,
                    this.state.apartment,
                    this.state.floor,
                    this.state.entrace,
                    this.state.typeDelivery,
                    moment(moment(this.state.dayOfDelivery).hour(moment(this.state.timeOfDelivery).hour()).minute(moment(this.state.timeOfDelivery).minute())).add(3, "hours"), // Заменить
                    this.state.comment,
                    this.state.paymentType,
                    dataForServer,
                    this.state.email,
                    this.state.timeDelivery
                ).then((res)=>{
                    this.props.history.push({
                        pathname:"/confirmation",
                        state:{
                            component: "confirmed",
                            number: res.data.OrderNumber
                        }
                    });
                })
                    .catch((res)=>{
                        this.props.history.push({
                            pathname:"/confirmation",
                            state:{
                                component: "error"
                            }
                        });
                    })
            }else{
                this.setState({warning: true})
            }
        }
    };
    getIDs = () => {
        if (localStorage.getItem("sdvItems") !== null){
            let arr = JSON.parse(localStorage.getItem("sdvItems")).items;
            let obj = {};
            arr.forEach((item)=>{obj[item] = obj[item] + 1 || 1});
            return obj;
        }else{
            return false;
        }
    };

    onWriteStreet = (e) => {
        this.setState({street: e.target.value})
    };
    onWriteBuilding = (e) => {
        this.setState({building: e.target.value})
    };
    onWriteKorpus = (e) => {
        this.setState({ korpus: isNaN(e.target.value) || e.target.value === "" ? 0 : e.target.value})
    };
    onWriteApartment = (e) =>{
        this.setState({apartment: e.target.value})
    };
    onWriteFloor = (e) => {
        this.setState({floor: e.target.value})
    }
    onWriteEntrance = (e) => {
        this.setState({entrace: e.target.value})
    }
    onWriteComment = (e) =>{
        this.setState({comment: e.target.value})
    };
    changeDeliveryType = (type) => {
        if (this.state.typeDelivery !== type) this.setState({typeDelivery: type})
    };
    changeDeliveryTime = (type) => {
        if (this.state.typeDelivery !== type) this.setState({timeDelivery: type})
    };
    onChangeDay = (day) => {
        this.setState({dayOfDelivery: day})
    };
    onChangeHours = (time) => {
        this.setState({timeOfDelivery: time})
    };
    onWriteName = (e) => {
        this.setState({name: e.target.value})
    };
    onWriteEmail = (e) => {
        this.setState({email: e.target.value})
    };
    onChangePaymentType = (type) => {
      this.setState({paymentType: type});
    };
    render() {
        return (
            <Page>
                <MetaTags>
                    <title>Оформление заказа | Семейный Дворик</title>
                </MetaTags>
                <div className="order-main" ref={this.contentRef}>
                    <div>
                    <div className="cart-title-container">
                        <div className="order-process">
                            <div className="order-process-item-box">
                                <div className="order-process-corcle-item-selected order-process-corcle-item-text-selected">1</div>
                                <p className="order-process-text-selected">КОРЗИНА</p>
                            </div>
                            <div className="order-process-between-line"></div>
                            <div className="order-process-item-box order-process-ordering">
                                <div className="order-process-corcle-item-selected order-process-corcle-item-text-selected">2</div>
                                <p className="order-process-text-selected">ОФОРМЛЕНИЕ<br></br>ЗАКАЗА</p>
                            </div>
                            <div className="order-process-between-line"></div>
                            <div className="order-process-item-box order-process-confirmed">
                                <div className="order-process-corcle-item order-process-corcle-item-text">3</div>
                                <p className="order-process-text">ЗАКАЗ<br></br>ПРИНЯТ</p>
                            </div>
                        </div>
                        <h2 className="order-title universal-page-header-text"> Оформление заказа </h2>
                    </div>
                    {/*<div className="order-breadcrumbs">
                        <div className="order-breadcrumbs-row">
                            <div className={`order-breadcrumbs-number ${this.state.step === 2 || this.state.step === 3 ? "obn-done": ""} 
                            ${this.state.step === 1 ? "obn-now" : ""}`}>1</div>
                            <div className="order-breadcrumbs-name">Адрес доставки</div>
                        </div>
                        <div className="order-breadcrumbs-row">
                            <div className={`order-breadcrumbs-number ${this.state.step === 3 ? "obn-done" : ""} 
                            ${this.state.step === 2 ? "obn-now" : ""}`}>2</div>
                            <div className="order-breadcrumbs-name">Контакты</div>
                        </div>
                        <div className="order-breadcrumbs-row">
                            <div className={`order-breadcrumbs-number ${this.state.step === 3 ? "obn-now" : ""}`}>3</div>
                            <div className="order-breadcrumbs-name">Оплата</div>
                        </div>
                    </div>*/}
                    <div className="order-container">
                        <div className="order-info-container">
                        <ContactStep
                            onClickBack={this.onClickBack}
                            onClickOnward={this.onClickOnward}
                            step={this.state.step}
                            onWriteName={this.onWriteName}
                            onWriteEmail={this.onWriteEmail}
                            name={this.state.name}
                            email={this.state.email}
                            warning={this.state.warning}
                            contactWarning={this.state.contactWarning}
                            />
                            <h2 className="order-sub-title universal-page-header-text">ДОСТАВКА</h2>
                        <DeliveryStep
                            onClickBack={this.onClickBack}
                            onClickOnward={this.onClickOnward}
                            step={this.state.step}
                            onWriteStreet={this.onWriteStreet}
                            onWriteBuilding={this.onWriteBuilding}
                            onWriteKorpus={this.onWriteKorpus}
                            onWriteApartment={this.onWriteApartment}
                            onWriteFloor={this.onWriteFloor}
                            onWriteEntrance={this.onWriteEntrance}
                            onWriteComment={this.onWriteComment}
                            changeDeliveryType={this.changeDeliveryType}
                            changeDeliveryTime={this.changeDeliveryTime}
                            typeDelivery={this.state.typeDelivery}
                            timeDelivery={this.state.timeDelivery}
                            onChangeDay={this.onChangeDay}
                            onChangeHours={this.onChangeHours}
                            warning={this.state.warning}
                            deliveryWarning={this.state.deliveryWarning}
                            dayOfDelivery={this.state.dayOfDelivery}
                            timeData={new Date(this.state.timeData)}
                            timeOfDelivery={this.state.timeOfDelivery}
                            />
                            <h2 className="order-sub-title universal-page-header-text">ОПЛАТА</h2>
                        <PaymentStep
                            onClickBack={this.onClickBack}
                            onClickOnward={this.onClickOnward}
                            step={this.state.step}
                            onChangePaymentType={this.onChangePaymentType}
                            warning={this.state.warning}
                        />
                            <label className="order-textarea order-checkbox-text-active">
                            Комментарий:<br />
                            <textarea className="coment-textarea" name="comment" placeholder="Комментарий" onChange={this.onWriteComment}>
                            </textarea>
                        </label>
                        </div>
                        <div className="order-right-column">
                        <Bill
                            typeDelivery={this.state.typeDelivery}
                            street={this.state.street}
                            building={this.state.building}
                            korpus={this.state.korpus}
                            apartment={this.state.apartment}
                            phone={this.state.userData.PhoneNumber}
                            name={this.state.name}
                            sum={this.props.sum.sumAndCount}
                            paymentType={this.state.paymentType}
                            deliveryData={this.state.deliveryData}
                            />
                            <BtnsBackOnward
                                onClickBack={this.onClickOnward}
                                onClickOnward={this.onClickOnward}
                                step={3} 
                                checkProps={this.checkProps}
                                contentRef={this.contentRef}/>
                        </div>
                        </div>
                        </div>
                </div>
            </Page>
        )
    }
}
export default connect(
    state =>(
        {
            token: ({token: state.token.Token}),
            sum: ({sumAndCount: state.sumAndCount.sum}),
            count: ({sumAndCount: state.sumAndCount.count})
        }
    ),
    dispatch => ({deleteToken: ()=>{dispatch({type: "DELETE_TOKEN"})}
}))(Order)