export function updateSumAndCount(payload){
  return {type: "UPDATE_SUM_AND_COUNT", payload}
}
export function addPhoneNumberToUser(payload){
    return {type: "USER_ADD_PHONE_NUMBER", payload}
}
export function addToken(payload){
    return {type: "ADD_TOKEN", payload}
}
export function deleteToken(){
    return {type: "DELETE_TOKEN"}
}