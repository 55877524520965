import React, { Component } from 'react';
import './HamburgerMenu.css';

export default class HamburgerMenu extends Component{

    render(){
        return(
             <div className={this.props.active ? "hamburger hamburger--collapse is-active": "hamburger hamburger--collapse"} type="button" onClick={()=>this.props.onClickHamburgerMenu()}>
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
             </div>
        )
    }
}