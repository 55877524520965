import React from 'react';
import { NavLink } from 'react-router-dom';
import './ItemOfNavigation.css';

const ItemOfNavigation = ({item, onClickNavItem})=>{
	return (
		<NavLink to={`/${item.Path}`} className="nav-item-icon" key={item.Name} onClick={onClickNavItem} >
			<img src={item.Icon} alt={item.Name} />
		</NavLink>
	);
};
export default ItemOfNavigation;