import React, { Component } from 'react';
import './DeliveryPage.css';
import MetaTags from 'react-meta-tags';
import Page from "../../components/Page/Page";
import bg from '../../assets/images/bgimg.png';

export default class DeliveryPage extends Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }
	render() {
		return (
			<Page>
				<MetaTags>
					<title> Доставка | Семейный дворик </title>
				</MetaTags>
                <div className="upage-main" ref={this.contentRef}>
                    <DeliveryTerms />
				</div>
			</Page>
		);
    }
    componentDidMount() {
        this.contentRef.current.scrollIntoView(true)
    }

    componentDidUpdate() {
        this.contentRef.current.scrollIntoView(true)
    };
}

const MapDelivery = () => {
	return (
		<iframe title="map"
			src="https://yandex.ru/map-widget/v1/?um=constructor%3A844cc5b03798653add6dda246456bdcf6bd1e7b079ed1a2992dd6a495cd99483&amp;source=constructor"
			width="100%"
			height="300"
			frameBorder={0}
			className="upage-map"
		/>
	)
};

const DeliveryTerms = () => (
    <div className="upage-container">
        <h2 className="upage-title universal-page-header-text">ДОСТАВКА</h2>
        <p className="delivery-page-under-header-text">Бесплатная доставка по городу Калуга при заказе от 950 рублей.</p>
        <MapDelivery />
        <ul className="delivery-list">
			<li className="simple-text">Заказ на доставку можете оформить по телефону <a href="tel:+79605180803">8 960 518 08 03 </a>. Ежедневно с 10:00 до 21:00.</li>
			<li className="simple-text">Заказы доставляются в течении 60 минут в зоне часовой доступности.</li>
			<li className="simple-text">Среднее время доставки 60 минут в зоне часовой доступности.</li>
			<li className="simple-text">Время доставки отдаленных районов и больших заказов может быть увеличено.</li>
			<li className="simple-text">Заказ может оформить лицо, достигшее 14 лет (в соответствии со статьей 26 ГК РФ - Дееспособность несовершеннолетних в возрасте от четырнадцати до восемнадцати лет).</li>
			<li className="simple-text">Можно заказать доставку к определенному времени, или забрать готовый заказ в кафе.</li>
			<li className="simple-text">Для получения заказа точно ко времени сделайте заказ заранее.</li>
			<li className="simple-text">Внимание: в период акций время ожидания заказов может быть увеличено.Бонусы при доставке не начисляются.</li>
		</ul>
	</div>
);