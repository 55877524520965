import React, {Component, Fragment} from 'react';
import './Notfound.css';
import MetaTags from "react-meta-tags";
import Page from "../../components/Page/Page";

export default class Notfound extends Component{
    render() {
        return (
            <Page>
                <MetaTags>
                    <title>Страница не найдена (404) | Семейный Дворик</title>
                </MetaTags>
                <div className="notfound-main">
                    <div>
                        <div className="notfound-digits">404</div>
                        <div className="notfound-text">Страница не найдена</div>
                    </div>
                </div>
            </Page>
        );
    }
};