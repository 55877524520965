import React, { Component } from 'react';
import './ModifiersOfItem.css';

export default class ModifiersOfItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentSelectedModifier: this.props.modifiers[0]
        }
    }
    select=(item)=>{
        this.setState({currentSelectedModifier: item});
        this.props.onChangeModifier(item);
    };

    render() {
        return(
            <div className="product-modifiers">
                {this.props.modifiers.map((item)=>{
                    return(
                            <div className={this.state.currentSelectedModifier.Id === item.Id ? "modifier-item is-active-item": "modifier-item" }
                                 key={item.Id} onClick={()=>this.select(item)}>
                                { item.Name}
                            </div>
                        )
                })}
            </div>
        )
    }
}