import React, {Component, Fragment} from 'react';
import './Bill.css';

class Bill extends Component{
    render() {
        let paymentType;
        switch (this.props.paymentType) {
            case 1:
                paymentType = "Банковской картой";
                break;
            case 2:
                paymentType = "Банковской картой (Курьеру)";
                break;
            case 3:
                paymentType = "Наличными";
                break;
            default:
                break;
        }
        return(
            <div className="bill-container">
                <div className="bill-header bill-header-text">Информация о заказе</div>
                <div>
                    <div className="bill-label bill-header-text">Номер телефона:</div>
                    <div className="bill-text">{this.props.phone}</div>
                </div>
                <div>
                    <div className="bill-label bill-header-text">Адрес:</div>
                    <div className="bill-text">
                        {this.props.typeDelivery === "delivery" ? `${this.props.street ? this.props.street: ""} 
                        ${this.props.building ? this.props.building+",": ""}
                         ${this.props.korpus ? "Корпус "+this.props.korpus+",": ""} 
                         ${this.props.apartment ? "кв. "+this.props.apartment : ""}`: "Калуга, Суворова 113" }
                    </div>
                </div>
                <div>
                    <div className="bill-label bill-header-text">Имя:</div>
                    <div className="bill-text">{this.props.name}</div>
                </div>
                <div>
                    <div className="bill-label bill-header-text"> Способ оплаты:</div>
                    <div className="bill-text">{paymentType}</div>
                </div>
                <div>
                    <div className="bill-label bill-header-text"> Сумма заказа:</div>
                    <div className="bill-text">{this.props.sum} рублей</div>
                </div>
                {
                    this.props.deliveryData && <Fragment>
                        <div>
                            <div className="bill-label bill-header-text"> Стоимость доставки:</div>
                            <div className="bill-text"> {this.props.sum > this.props.deliveryData.FreeDeliverySum || this.props.typeDelivery !== "delivery" ? "Бесплатно" : this.props.deliveryData.DeliveryPrice +" рублей"} </div>
                        </div>
                        <div className="bill-itogo bill-itogo-text">Итого: {this.props.sum + (this.props.sum > this.props.deliveryData.FreeDeliverySum ||
                        this.props.typeDelivery !== "delivery"? 0 : +this.props.deliveryData.DeliveryPrice)} P</div>
                    </Fragment>
                }

            </div>
        )
    }
}
export default Bill;