import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Page from "../../../components/Page/Page";

export default class PrivacyPolicy extends Component {
	render() {
		return (
			<Page>
				<MetaTags>
					<title> Политика конфиденциальности | Семейный дворик </title>
				</MetaTags>

				<div className="privacy-container">
					<h1>Политика конфиденциальности</h1>
					<h1>(действует с 21 марта 2018 года)</h1>

						<h3>1. Общие положения</h3>
						<p>1.1. Настоящая Политика конфиденциальности (далее - "Политика") действует в отношениях между Пользователями и индивидуальным предпринимателем Яковенко Наталья Сергеевна (ИНН 402720065581, ОГРНИП 316402700100826), местонахождение по адресу: 248000, г.Калуга, ул. Циолковского, дом 5, кв.70 и/или его аффилированные лицами, включая всех лиц, объединенных в рамках кафе "Семейный дворик". (далее - "Компания"). </p>
						<p>1.2. В рамках настоящей Политики под "персональной информацией Пользователя" понимаются: </p>
						<p>1.2.1. персональные данные, которые Пользователь предоставляет о себе (фамилию, имя, отчество; дата рождения; пол; адрес доставки; номер телефона; адрес электронной почты и иные сведения) во время использования им любого из сайтов, сервисов, служб и программ Компании (далее - "Сервисы"). </p>
						<p>1.2.2. копия паспорта или иного документа, удостоверяющего личность клиента. </p>
						<p>1.3. Компания не проверяет достоверность персональной информации, предоставляемой Пользователями. Однако Компания исходит из того, что Пользователь предоставляет достоверную и достаточную персональную информацию, и поддерживает эту информацию в актуальном состоянии. </p>
						<p>1.4. Компания может осуществлять аудиозапись телефонного разговора с Пользователем, независимо от получения и/или неполучения от Пользователя согласия на аудиозапись телефонного разговора, если указанные действия необходимы Компании для исполнения своих обязательств, оценки качества используемых Сервисов и оказанных Пользователю услуг, а также для достижения целей обработки персональных данных, перечисленных в пункте 2.1. настоящей Политики. </p>
						<p>1.5. Не могут быть отнесены к персональным данным Пользователя такие данные, как: номер телефона и адрес электронной почты, если обработка этих данных осуществляется обезличенно, то есть без привязки к персональным данным конкретного Пользователя, его истории заказов товаров Компании. </p>
						<p>1.6. Данные, которые автоматически передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы не являются персональными данными. </p>
						<p>1.7. Настоящая Политика применима только к Сервисам Компании. Компания не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайтах Компании. На таких сайтах у Пользователя может собираться или запрашиваться иная персональная информация, а также могут совершаться иные действия. </p>
						<p>1.8. Под обработкой персональных данных в настоящей Политике понимается: сбор вышеуказанных данных, их систематизация, накопление, хранение, уточнение (обновление, изменение), использование, блокирование, уничтожение. </p>
						<h3>2. Цели сбора и обработки персональной информации Пользователей</h3>
						<p>2.1. Персональную информацию Пользователя Компания может обрабатываться в следующих целях: </p>
						<p>2.1.1. идентификация Пользователя при исполнении Публичной оферты о продаже товаров дистанционным способом и договоров с Компанией; </p>
						<p>2.1.2. направление кассового чека в электронном виде на адрес электронной почты и/или телефон Пользователя; </p>
						<p>2.1.3. предоставление Пользователю персонализированных Сервисов; </p>
						<p>2.1.4. связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Сервисов, оказания услуг по заказу и доставке товаров Компании, а также обработка запросов и заявок от Пользователя; </p>
						<p>2.1.5. улучшение качества Сервисов, удобства их использования, разработка новых Сервисов и услуг; </p>
						<p>2.1.6. рекламы своих товаров и услуг; </p>
						<p>2.1.7. проведение статистических и иных исследований на основе предоставленных данных; </p>
						<p>2.1.8. иных целях, не противоречащих действующему законодательству РФ. </p>
						<h3>3. Условия обработки персональной информации Пользователя и её передачи третьим лицам</h3>
						<p>3.1. Использование Сервисов означает согласие Пользователя с Политикой. В случае несогласия с этими условиями Пользователь должен воздержаться от использования Сервисов. </p>
						<p>3.2. Согласие Пользователя на предоставление Персональной информации, данное им в соответствии с настоящей Политикой в рамках отношений с одним из лиц, входящих в Компанию, распространяется на все лица, входящие в Компанию. </p>
						<p>3.3. Согласие Пользователя на обработку его персональных данных действует со дня начала пользования Сервисов до дня отзыва согласия, посредством обращения Пользователя к представителям Компании посредством направления Пользователем письма по электронной почте на адрес semejnyjdvorik@gmail.com . </p>
						<p>3.4. Пользователь вправе запросить перечень своих персональных данных и/или потребовать изменить, уничтожить свои персональные данные, посредством направления Пользователем письма по электронной почте на адрес semejnyjdvorik@gmail.com и указав свои имя, отчество, фамилию, дату рождения, адрес доставки, телефон и адрес электронной почты. </p>
						<p>3.5. Обработка таких персональных данных, как: фамилия, имя, отчество; дата рождения; пол; адрес доставки, телефон, адрес электронной почты может осуществляться оператором Компании независимо от получения и/или неполучения от Пользователя согласия на их обработку, если указанные персональные данные необходимы Компании для выполнения заказа и доставки товаров Пользователю и оценки качества оказанных услуг. </p>
						<p>3.6. Компания хранит персональную информацию Пользователей, обеспечивая их конфиденциальность и защиту от неправомерного или случайного доступа к ним третьих лиц. </p>
						<p>3.7. Компания вправе передать персональную информацию Пользователя третьим лицам в следующих случаях: </p>
						<p>3.7.1. передача необходима в рамках использования Пользователем определенного Сервиса, либо для оказания услуги Пользователю; </p>
						<p>3.7.2. такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации; </p>
						<p>3.7.3. в целях обеспечения возможности защиты прав и законных интересов Компании или третьих лиц в случаях, когда Пользователь нарушает условия Публичной оферты; </p>
						<p>3.7.4. в целях доставки товаров Пользователю посредством курьерской службы Компании; </p>
						<p>3.7.5. в целях сохранения и проведения анализа истории заказов товаров Пользователем посредством использования операторов Компании, осуществляющих прием заказов товаров по телефону; </p>
						<p>3.7.6. в целях совершения операторами Компании и иными уполномоченными представителями Компании исходящих опросов и вызовов в адрес Пользователя в целях проведения качественного и количественного анализа предоставляемого Сервиса и оказанных услуг, исследования предпочтений Пользователя, проведения маркетинговых исследований, проведение розыгрышей призов среди Пользователей, оценки удовлетворенности Пользователя Сервисами и услугами Компании, урегулирования конфликтных ситуаций. </p>
						<p>3.8. Компания имеет право назначить лицо (оператора Компании), ответственное за организацию обработки персональных данных Пользователей, в целях реализации положений настоящей Политики. </p>
						<p>3.9. Компания вправе самостоятельно определять используемые способы обработки персональных данных Пользователей (включая, но, не ограничиваясь: автоматическая сверка почтовых кодов с базой кодов/индексов, автоматическая проверка написания названий улиц/населенных пунктов, сегментация базы данных по заданным критериям). </p>
						<h3>4. Меры, применяемые для защиты персональной информации Пользователей</h3>
						<p>4.1. Компания принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц. </p>
						<p>4.2. Компания обеспечивает конфиденциальность предоставленных Пользователем персональных данных, их защиту от копирования, распространения. </p>
						<p>4.3. Компания обязуется предотвращать попытки несанкционированного доступа к персональным данным Пользователей, предоставленных Компании; своевременно обнаруживать и пресекать такие попытки. </p>
						<p>4.4. Компания устанавливает следующие организационные меры для защиты Персональной информации: </p>
						<p>4.4.1. организация режима обеспечения безопасности помещений, в которых размещено оборудование (серверы) для обработки и хранения персональных данных Пользователей в информационной системе персональных данных, препятствующего возможности неконтролируемого проникновения и пребывания в помещениях лиц, не имеющих прав доступ в эти помещения; </p>
						<p>4.4.2. обеспечение сохранности носителей персональных данных; </p>
						<p>4.4.3. утверждение перечня лиц, имеющих право доступа к персональным данным Пользователей в рамках выполнения своих служебных обязанностей; </p>
						<p>4.4.4. назначение приказом должностных лиц, ответственных за обеспечение безопасности персональных данных Пользователей в информационной системе персональных данных; </p>
						<p>4.4.5. использование средств защиты информации, прошедших процедуру оценки соответствия требованиям законодательства Российской Федерации (сертификация) в области обеспечения безопасности информации, в случае, когда применение таких средств необходимо для нейтрализации актуальных угроз; </p>
						<p>4.4.6. установление правил доступа к персональным данным, обрабатываемым в информационной системе персональных данных, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными Пользователей в информационной системе персональных данных; </p>
						<p>4.4.7. проведение профилактической работы с операторами Компании по предупреждению разглашения ими персональных данных Пользователей; </p>
						<p>4.4.8. ознакомление операторов Компании с действующими нормативами в области защиты персональных данных и локальными актами; </p>
						<p>4.4.9. проведение систематических проверок соответствующих знаний операторов Компании, обрабатывающих персональные данные Пользователей, и соблюдения ими требований нормативных документов по защите конфиденциальных сведений; </p>
						<p>4.4.10. обеспечение контроля за принимаемыми мерами по обеспечению безопасности персональных данных Пользователей и уровня защищенности информационных систем персональных данных. </p>
						<h3>5. Изменение Политики конфиденциальности</h3>
						<p>5.1. Компания имеет право вносить изменения в настоящую Политику. При внесении изменений в актуальной редакции указывается дата последнего обновления. Пользователь обязуется самостоятельно контролировать наличие изменений в настоящей Политике. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция всегда находится на Сайте. </p>
						<h3>6. Заключительные положения</h3>
						<p>6.1. Настоящая Политика регулируется нормами действующего законодательства РФ. </p>
						<p>6.2. Все возможные споры относительно настоящей Политики разрешаются согласно нормам действующего законодательства РФ. </p>
				</div>
			</Page>
		);
	}
}