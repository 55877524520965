import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Page from "../../../components/Page/Page";

export default class Terms extends Component {
	render() {
		return (
			<Page>
				<MetaTags>
					<title> Пользовательское соглашение | Семейный дворик </title>
				</MetaTags>

				<div class="terms-container">
					<h1>Пользовательское соглашение</h1>
					<h1> (действует с 21 марта 2018 года) </h1>

					<h3>1. Статус Пользовательского соглашения </h3>
					<p>1.1. Настоящее Пользовательское соглашение является юридически обязательным соглашением между Посетителем, Пользователем и Компанией, предметом которого является предоставление Компанией Посетителю, Пользователю доступа к ознакомлению с Сайтом и возможность использовать функционал Сайта для совершения заказа Товаров после регистрации Пользователя на Сайте. </p>
					<p>1.2. Посетитель и Пользователь обязаны полностью ознакомиться с настоящим Пользовательским соглашением до момента использования Сайта. </p>
					<p>1.3. Посетитель, Пользователь принимают условия настоящего Пользовательского соглашения, в полном объеме и без каких-либо изъятий. </p>
					<p>1.4. В случае несогласия с перечисленными условиями Посетитель и Пользователь должны воздержаться от использования Сайта. </p>
					<h3>2. Термины и определения</h3>
					<p>2.1. В настоящем Пользовательском соглашении, если из контекста не следует иное, нижеприведенные термины с заглавной буквы имеют следующие значения: </p>
					<p>2.1.1. "Компания" - юридическое лицо или индивидуальный предприниматель, осуществляющий продажу Товаров, в том числе дистанционным способом через Сайт. в отношении настоящей редакции Пользовательского соглашения под "Компанией" следует понимать индивидуальный предприниматель Яковенко Наталья Сергеевна (ИНН 402720065581, ОГРНИП 316402700100826), местонахождение по адресу: 248000, г.Калуга, ул. Циолковского, дом 5, кв.70 и/или его аффилированные лица, включая все лица, объединенные в рамках кафе "Семейный дворик". </p>
					<p>2.1.2. "Посетитель" - любое дееспособное лицо, желающее ознакомится с содержанием Сайта, или намеренное заказать или приобрести Товары Компании исключительно для личных, семейных, домашних или иных нужд, не связанных с осуществлением предпринимательской деятельности. </p>
					<p>2.1.3. "Пользователь" - Посетитель, который уже зарегистрирован на Сайте (имеет личную учетную запись). </p>
					<p>2.1.4. "Товары" - продукция Компании (в том числе сувенирная продукция Компании), представленная на Сайте. </p>
					<p>2.1.5. "Сайт" - страница Интернет-магазина, на которой размещен перечень продукции Компании, по следующему адресу: www.semeinyidvorik.ru. </p>
					<p>2.2. Для целей настоящего Пользовательского соглашения термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе и наоборот. </p><h3>3. Предмет Пользовательского Соглашения</h3>
					<p>3.1. Предметом настоящего Пользовательского соглашения являются порядок и правила использования функционала (сервисов) Сайта, установленные Компанией для Посетителей и Пользователей Сайта. </p>
					<p>3.2. Компания гарантирует Пользователям возможность совершить заказ Товаров и его оплату через Сайт только в случае исправной работы всех коммуникационных сетей и не несет ответственности за неисполнение заказа Товаров в случае технических проблем на Сайте. </p>
					<h3>4. Регистрация и предоставление услуг</h3>
					<p>4.1. Для получения права оформить заказ Товаров через Сайт Пользователь обязуется осуществить регистрацию учетной записи Пользователя на Сайте. </p>
					<p>4.2. Для регистрации на Сайте Пользователю следует произвести следующие действия: </p>
					<p>4.2.1. ввести в форму абонентский номер телефона в федеральном формате (+7(ХХХ)ХХХ-ХХ-ХХ); </p>
					<p>4.2.2. указанный Пользователем при регистрации абонентский номер телефона будет использоваться в качестве имени Пользователя (логин) при использовании Сайта; </p>
					<p>4.2.3. ввести пароль, предоставляемый Пользователю в виде SMS-сообщения на указанный им абонентский номер; </p>
					<p>4.2.4. указать фамилию, имя, отчество; дату рождения; пол; адрес доставки; телефон; адрес электронной почты; </p>
					<p>4.3. Пользователь согласен на получение от Компании уведомлений о проводимых акциях и рекламных материалов по сетям электросвязи. Согласие может быть отозвано Пользователем в любой момент путем отказа от получения уведомлений в личном кабинете или направления отказа Компании. </p>
					<p>4.4. В случае несогласия с перечисленными условиями Пользователь должен воздержаться от использования сервисов Сайта. </p>
					<p>4.5. Пользователю запрещается передавать данные своей учетной записи третьим лицам. в случае передачи Пользователем своего логина и(или) пароля третьему лицу, Пользователь несет ответственность за несанкционированные действия третьего лица, как за свои собственные. </p>
					<p>4.6. Пользователь несет ответственность за точность и правильность, полноту и достоверность указания своих личных данных при регистрации учетной записи на Сайте и за последствия в виде убытков, которые могут возникнуть у Пользователя, в случае некорректного ввода указанных сведений. </p>
					<p>4.7. Пользователь обязан незамедлительно уведомить Компанию о любом случае несанкционированного доступа к учетной записи Пользователя, а также о любом случае нарушения безопасности своего логина и пароля (утеря, передача данных третьим лицам, другое). </p>
					<p>4.8. Любые действия, совершенные на Сайте с использованием логина и пароля Пользователя, считаются совершенными соответствующим Пользователем. </p>
					<p>4.9. Пользователь несет личную ответственность за любую информацию, которая размещается на Сайте посредством его учетной записи. </p>
					<h3>5. Условия использования информации, размещенной на Сайте</h3>
					<p>5.1. Сайт включает, но не ограничивается, следующим: тексты, фотографии, графические изображения, товарные знаки и иные, охраняемые законом, материалы. </p>
					<p>5.2. Все содержание Сайта охраняется авторским правом в соответствии с действующим гражданским законодательством Российской Федерации. </p>
					<p>5.3. Авторское право на использование содержания Сайта принадлежит Компании. </p>
					<p>5.4. Компания предоставляет Посетителю и/или Пользователю неисключительную лицензию на использование содержания Сайта в пределах, установленных настоящим Пользовательским соглашением. </p>
					<p>5.5. Посетитель и/или Пользователь обязуется использовать Сайт только в законных целях и способами, не нарушающим права третьих лиц. </p>
					<p>5.6. Компания не несет ответственность за вред, причиненный технике Посетителя и/или Пользователя в случае, если это произошло в результате перехода по гипертекстуальным ссылкам, размещенным на Сайте. </p>
					<h3>6. Полномочия Компании</h3>
					<p>6.1. Компания оставляет за собой право без какого-либо специального уведомления вносить изменения в настоящее Пользовательское соглашение, в связи с чем Посетитель и/или Пользователь обязуется самостоятельно контролировать наличие изменений в настоящим Пользовательском соглашении. Новая редакция Пользовательского соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Пользовательского соглашения. Действующая редакция Пользовательского соглашения находится на Сайте. </p>
					<p>6.2. Компания оставляет за собой право изменять Сайт, в том числе изменять или добавлять в его структуру разделы, менять дизайн и совершать прочие действия, направленные на повышение функциональности Сайта и удобства совершения заказа Товаров Пользователями и ознакомления Посетителя и/или Пользователя с информацией, размещенной Компанией на Сайте. </p>
					<p>6.3. Компания вправе произвести блокировку, исключение, удаление информации, размещенной Пользователем, без согласия последнего, если такая информация не отвечает требованиям действующего российского законодательства и положениям настоящего Пользовательского соглашения. </p>
					<p>6.4. Компания вправе произвести приостановление оказания услуг пользования Сайтом, либо отказать Пользователю в возможности использования отдельных ресурсов Сайта, либо отменить регистрацию учетной записи Пользователя, если у Компании есть основания полагать, что предоставленная Пользователем информация о своих персональных данных неверна (искажена, недостоверна). </p>
					<p>6.5. Компания вправе осуществить действия по удалению учетной записи Пользователя в случае систематического нарушения последним положений настоящего Пользовательского соглашения. </p>
					<h3>7. Технологии, используемые Компанией на Сайте</h3>
					<p>7.1. Компания вправе использовать технологию "cookie" для сохранения информации об ip-адресе Посетителей и Пользователей Сайта. "Cookie" не используются для сохранения конфиденциальной информации о Посетителях и Пользователях Сайта и не направлены на установление личности Посетителя и Пользователей Сайта. Вся информация, которая собирается и анализируется, анонимна. </p>
					<p>7.2. Использование технологии "cookie" представляет собой размещение на Сайте определенного набора символов (знаков), которые сохраняются на компьютере (далее - "устройство", с которого был выполнен вход на Сайт) Посетителей и Пользователей Сайта, при доступе к определенным местам Сайта. </p>
					<p>7.3. Файлы "cookie", которые размещаются на устройстве Посетителя и/или Пользователя администрацией Сайта www.semeinyidvorik.ru, называются "собственными". Файлы "cookie", которые размещаются на устройстве Посетителя и/или Пользователя другими операторами, называются файлами "третьих лиц". </p>
					<p>7.4. Собственные файлы "cookie", используемые Компанией: </p>
					<p>7.4.1. SessionId - сессия на Сайте. Позволяет перемещаться по Сайту без необходимости совершения повторного входа, повторного добавления продуктов и товаров в корзину, повторного выбора города (адреса места нахождения), повторного выбора адреса доставки или самовывоза. </p>
					<p>7.4.2. SessionIdTemp - сессия на Сайте. Позволяет перемещаться по Сайту без необходимости совершения повторного входа, повторного добавления продуктов и товаров в корзину, повторного выбора города (адреса места нахождения), повторного выбора адреса доставки или самовывоза. </p>
					<p>7.4.3. ID - идентификатор Пользователя. Позволяет возвращаться на Сайт без необходимости совершения повторного входа и повторного ввода адреса места нахождения Пользователя и/или Посетителя. Позволяет Пользователям, зарегистрированным на Сайте и участвующим в Программе Лояльности, видеть накопленные бонусы. </p>
					<p>7.5. Компания имеет право передавать информацию об ip-адресах Посетителей и Пользователей Сайта своим партнерам и третьим лицам, имеющим заключенные с Компанией договоры, для исполнения обязательств перед Посетителем и Пользователем. </p>
					<h3>8. Заключительные положения</h3>
					<p>8.1. Настоящее Пользовательское соглашение регулируется нормами действующего российского законодательства. </p>
					<p>8.2. Настоящее Пользовательское соглашение распространяется на Посетителей и Пользователей. </p>
					<p>8.3. Все возможные споры относительно настоящего Пользовательского соглашения разрешаются согласно нормам действующего российского законодательства. </p>
					<p>8.4. Ввиду предоставления Пользователями своих персональных данных при регистрации на Сайте, Компанией совершает действия, направленные на сбор, запись, хранение и систематизацию указанных данных физических лиц с, предусмотренных настоящим Пользовательским соглашением, соблюдением требований, предусмотренных Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных", и в соответствии с разработанной Компанией Политикой конфиденциальности. </p>
				</div>
			</Page>
		);
	}
}