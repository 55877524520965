import React, {Component, Fragment, useEffect} from 'react';
import MetaTags from "react-meta-tags";
import Page from "../../components/Page/Page";
import './Confirmation.css';
import {connect} from "react-redux";
import { updateSumAndCount } from "../../actions";
import { Link } from "react-router-dom";
import bg from '../../assets/images/bgimg.png';

class Confirmation extends Component{
    state = {component: null};

    componentDidMount() {
        if(this.props.location.state !== undefined){
            this.setState({component: this.props.location.state.component});
        }else{
            this.props.history.push({
                pathname:"/",
            });
        }
    }

    render() {
        return(
            <Page>
                <MetaTags>
                    <title>Подтверждение | Семейный Дворик</title>
                </MetaTags>
                <div className="confirmation-main">
                        <div className="order-process">
                            <div className="order-process-item-box">
                                <div className="order-process-corcle-item-selected order-process-corcle-item-text-selected">1</div>
                                <p className="order-process-text-selected">КОРЗИНА</p>
                            </div>
                            <div className="order-process-between-line"></div>
                            <div className="order-process-item-box order-process-ordering">
                                <div className="order-process-corcle-item-selected order-process-corcle-item-text-selected">2</div>
                                <p className="order-process-text-selected">ОФОРМЛЕНИЕ<br></br>ЗАКАЗА</p>
                            </div>
                            <div className="order-process-between-line"></div>
                            <div className="order-process-item-box order-process-confirmed">
                            <div className="order-process-corcle-item-selected order-process-corcle-item-text-selected">3</div>
                            <p className="order-process-text-selected">ЗАКАЗ<br></br>ПРИНЯТ</p>
                            </div>
                        </div>
                        {this.state.component === "confirmed" && <Confirmed number={this.props.location.state.number} update={this.props.updateCounter}/>}
                    {this.state.component === "error" && <Error />}
                    <Link to="/">
                        <button className="menu-returning-button">Вернуться в меню</button>
                    </Link>
                </div>
            </Page>
        )
    }
}

const Confirmed = ({number, update}) => {
    useEffect(()=>{
        localStorage.removeItem("sdvItems");
        update();
    });
    return(
        <div className="confirmation-confirmed">
            <div className="confirmation-confirmed-1 universal-page-header-text">Заказ успешно оформлен</div>
            <div className="confirmation-confirmed-2">Ваш номер заказа: {number}</div>
        </div>
    )
};

const Error = () => {
    return(
        <div className="confirmation-confirmed">
            <div className="confirmation-error universal-page-header-text">Заказ успешно оформлен</div>
            <div className="confirmation-confirmed-2"> </div>
        </div>
    )
};
export default connect(state => ({}), dispatch => ({updateCounter: ()=> {dispatch(updateSumAndCount())}}))(Confirmation);