import React, { Component } from 'react';
import {connect} from 'react-redux'
import './ShoppingCart.css';
import cart from '../../../assets/icons/cart.svg';
import {Link} from "react-router-dom";

class ShoppingCart extends Component{
    render(){
        const {count, sum} = this.props.sumAndCount;
        return(
                <Link to="/cart" className="shopping-cart-container-link">
                <div className="cart-btn">
                    <img src={cart} alt="Корзина" className="cart-logo"/>
                </div>
                    {count > 0 &&
                    <div className="shopping-cart-info">
                        <div className="cart-label">{sum}₽</div>
                        <div className="cart-count-and-price">{count} шт.</div>
                    </div>
                    }
                </Link>
        )
    }
}

export default connect(state =>({sumAndCount: state.sumAndCount}))(ShoppingCart)